import React from 'react'
import moment from '../moment'

const TestValue = ({ created, value, comment, remind }) => {

  if (!value) {
    return null
  }

  const expired = !!remind && (remind <= moment().format('YYYY-MM-DD'))

  return (
    <span
      className={`test-value ${expired ? 'expired' : ''}`}
      title={expired ? 'Срок напоминания истек!' : ''}
    >
      <span className="date">{moment(created).format('L')}</span>
      <span className="value-comment" title={comment}>{value}</span>
    </span>
  )
}

export default TestValue
