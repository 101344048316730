import React from 'react'
import ListEditorForm from './ListEditorForm'
import { ItemButtons } from '../ListEditor'
import Button from '../ButtonAwesome'
import { saveConfigSection } from '../../hoc/config'

class ConfigEditParams extends React.Component {
  state = {}

  handleSave = data => {
    // console.log(data)
    saveConfigSection(this.props.section, data)
      .then(() => {
        alert('Сохранено!')
      })
      .catch(alert)
  }

  render() {
    return (
      <ListEditorForm
        items={this.props.items}
        itemComponent={ConfigEditParamsItem}
        onSave={this.handleSave}
      />
    )
  }
}

class ConfigEditParamsItem extends React.Component {
  state = {
    enableOptions: !!this.props.item.options,
  }

  handleChangeEnableOptions = event => {
    this.setState({ enableOptions: event.target.checked })
  }

  render() {
    const { item, actions } = this.props

    return (
      <div className="params-item">
        <div className="params-item-title">
          <input
            type="text"
            name={`${item.key}[title]`}
            placeholder="Название"
            defaultValue={item.title}
            required={true}
          />

          <label>
            <input
              type="checkbox"
              checked={this.state.enableOptions}
              onChange={this.handleChangeEnableOptions}
            />{' '}
            Опции
          </label>

          <ItemButtons actions={actions} />
        </div>

        {this.state.enableOptions && (
          <div className="params-item-options">
            <span>Список опций:</span>
            <ItemOptions name={item.key} options={item.options} />
          </div>
        )}
      </div>
    )
  }
}

class ItemOptions extends React.Component {
  state = {
    options: this.props.options || [''],
  }

  addOption = () => {
    const options = [...this.state.options, '']
    this.setState({ options })
  }

  removeOption = index => () => {
    const options = [...this.state.options]
    options.splice(index, 1)
    this.setState({ options })
  }

  render() {
    const { name } = this.props
    const { options } = this.state

    return (
      <ul className="list-editor-item-options">
        {options.map((option, index) => (
          <li key={option}>
            <input
              type="text"
              name={`${name}[options][]`}
              defaultValue={option}
            />
            {index === options.length - 1 ? (
              <Button icon="plus" onClick={this.addOption} />
            ) : (
              <Button icon="remove" onClick={this.removeOption(index)} />
            )}
          </li>
        ))}
      </ul>
    )
  }
}

export default ConfigEditParams
