import React, { useEffect, useMemo, useState } from 'react'
import firebase from '../firebase'
import moment from '../moment'
import { useConfig } from '../hoc/config'
import { antropometry as calculatedValues } from '../calc'

const PageAntropometry = ({ patientKey, limitPrevTests = 3 }) => {
  const path = `tests/${patientKey}/antropometry`

  const [prevTestsData, setPrevTestsData] = useState()

  const prevTests = useMemo(() => {
    return Object.entries(prevTestsData ?? {}).map(([key, values]) => {
      return {
        key,
        date: moment(+key).format('L'),
        values,
      }
    })
  }, [prevTestsData])

  useEffect(() => {
    const ref = firebase.database().ref(path).limitToLast(limitPrevTests)
    const handleValue = snapshot => setPrevTestsData(snapshot.val())

    ref.on('value', handleValue)
    return () => ref.off('value', handleValue)
  }, [path, limitPrevTests])

  const config = useConfig(state => state.data)

  const params = useAntropometryParams(config)

  const [busy, setBusy] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()

    const form = e.target

    const values = Object.fromEntries(
      params
        .filter(param => !param.calculated)
        .filter(param => form[param.code].value !== '')
        .map(param => [param.code, form[param.code].value]),
    )

    if (Object.keys(values).length === 0) {
      alert('Невозможно сохранить пустой набор параметров!')
      return
    }

    try {
      setBusy(true)
      await firebase.database().ref(path).child(Date.now()).set(values)
      form.reset()
    } catch (reason) {
      console.error(reason)
      alert('Ошибка базы данных: Не удалось сохранить параметры!')
    } finally {
      setBusy(false)
    }
  }

  return (
    <form action="" onSubmit={handleSubmit}>
      <table className="antropometric-data">
        <thead>
          <tr>
            <th>Показатель</th>
            {prevTests.map(test => (
              <th key={test.key}>{test.date}</th>
            ))}
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {params.map(param => (
            <tr key={param.code}>
              <td>{param.title}</td>
              {prevTests.map(test => (
                <td
                  key={test.key}
                  className={`value ${param.calculated ? 'calculated' : ''}`}
                >
                  {param.value(test.values) || '-'}
                </td>
              ))}
              <td className={`input ${param.calculated ? 'calculated' : ''}`}>
                {param.calculated ? (
                  '(авторасчёт)'
                ) : (
                  <input type="text" name={param.code} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            {prevTests.length ? <td colSpan={prevTests.length} /> : null}
            <td>
              <button type="submit" disabled={busy}>
                Сохранить
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </form>
  )
}

export const useAntropometryParams = config => {
  return useMemo(() => {
    return Object.entries(config.antropometry).map(([code, param]) => {
      return {
        code,
        title: typeof param === 'string' ? param : param.title,
        calculated: !!param.calculated,
        value: values => {
          if (!param.calculated) {
            return values?.[code]
          }
          if (code in calculatedValues) {
            return calculatedValues[code](values ?? {})
          }
          console.warn('Invalid calculated value:', code)
        },
      }
    })
  }, [config])
}

export default PageAntropometry
