import React from 'react'

const MealsList = ({ meals }) => (
  <React.Fragment>
    {meals.length ? (
      <table className="meals">
        <thead>
          <tr>
            <th className="index">№</th>
            <th className="time">Время</th>
            <th>Перечень блюд</th>
          </tr>
        </thead>

        <tbody>
          {meals.map((meal, index) => (
            <tr key={index}>
              <td className="index">{index + 1}</td>
              <td className="time">{meal.time || '--:--'}</td>
              <td>
                <pre className="comment">{meal.desc}</pre>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>(нет данных)</p>
    )}
  </React.Fragment>
)

export default MealsList
