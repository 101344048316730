import React from 'react'
import { withHandlers } from 'recompose'

const TestInput = ({ name, type = "text", handleChange }) => (
  <input
    type={type}
    name={name}
    onChange={handleChange}
  />
)

const enhance = withHandlers({
  handleChange: props => event => {
    props.onChange && props.onChange(event.target.value)
  }
})

export default enhance(TestInput)
