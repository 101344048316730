const parseValue = value => {
  if (value) {
    return Number(value.replace(',', '.'))
  } else {
    return 0
  }
}

export const antropometry = {
  ['OTA_OBE']: values => {
    const ota = parseValue(values['OTA'])
    const obe = parseValue(values['OBE'])
    return ota > 0 && obe > 0 ? (ota / obe).toFixed(2) : ''
  },
}
