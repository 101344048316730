import React from 'react'

import PageParams from './Params'
import PageBigText from './BigText'

const PageAndrology = props => (
  <div className="page page-andrology">

    <h4>По опроснику у М:</h4>
    <PageBigText {...props} writing="questionnaire" />

    <PageParams {...props} set="andrology" />

    <h4>Изменение массы в течение жизни</h4>
    <PageBigText {...props} writing="andrology" />

  </div>
)

export default PageAndrology
