import React from 'react'
import serialize from 'form-serialize'
import firebase from '../firebase'
import moment from '../moment'

// import TestsSetForm from '../components/TestsSetForm'
import SetForm from '../components/TestsPendingForm'

class PageTestsPending extends React.Component {

  state = {}

  saveHistory = ({ created, values, comments = {} }) => {
    const ref = firebase.database().ref(this.props.refPath)

    const promises = Object.keys(values).map(key => {
      return ref.child(`history/${key}/${created}`).set({
        value: values[key],
        comment: comments[key] || ''
      })
    })

    return Promise.all(promises)
  }

  saveLastValues = ({ created, values, comments = {}, reminders = {} }) => {
    const ref = firebase.database().ref(this.props.refPath)

    const promises = Object.keys(values).map(key => {

      const lastValue = {
        created,
        value: values[key],
        comment: comments[key] || '',
        remind: reminders[key] ? moment(created).add(+reminders[key], 'months').format('YYYY-MM-DD') : ''
      }

      return ref.child(`last/${key}`).set(lastValue)
    })

    return Promise.all(promises)
  }

  removePendingSet = key => {
    const ref = firebase.database().ref(this.props.refPath)
    return ref.child(`pending/${key}`).remove()
  }

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const data = serialize(form, { hash: true })
    const { values, comments, created, set, reminders } = data

    if (!values || !Object.keys(values).every(key => !!values[key])) {
      alert('Заполните, пожалуйста, все показатели!')
      return
    }

    Promise.all([
      this.removePendingSet(set),
      this.saveHistory({ values, created, comments }),
      this.saveLastValues({ values, created, comments, reminders }),
    ]).then(() => console.log('Values saved successfully!'))

  }

  render() {

    const { pendingSets, tests, groups } = this.props

    if (pendingSets.length === 0) {
      return <p>(нет незаполненных анализов)</p>
    }

    return (
      <React.Fragment>
        {pendingSets.map(set => (
          <SetForm
            key={set.created}
            set={set}
            tests={tests}
            groups={groups}
            onSubmit={this.handleSubmit}
          />
        ))}
      </React.Fragment>
    )
  }
}

export default PageTestsPending
