import React from 'react'
import serialize from 'form-serialize'

import firebase from '../firebase'

import MealsForm from '../components/MealsForm'
import MealsList from '../components/MealsList'
import PageParams from '../pages/Params'
import Spinner from '../components/Spinner'

class PageRation extends React.Component {

  state = {
    editMeals: this.props.editMeals || false,
    meals: null,
  }

  componentDidMount() {
    const path = `ration/${this.props.patientKey}`

    this.ref = firebase.database().ref(path)
    this.refListener = this.ref.on('value', snapshot => {
      const meals = []
      snapshot.forEach(child => {
        const meal = { time: child.key, desc: child.val() }
        meals.push(meal)
      })
      this.setState({ meals })
    })
  }

  componentWillUnmount() {
    this.ref.off('value', this.refListener)
  }

  handleClickEdit = () => {
    this.setState({ editMeals: true })
  }

  handleSubmitMeals = form => {

    let { meals } = serialize(form, { hash: true })

    // meals = meals.sort((a, b) => (a.time < b.time ? -1 : a.time > b.time ? 1 : 0))
    meals = meals.filter(meal => (meal && !!meal.time)).reduce((acc, meal) => {
      acc[meal.time] = meal.desc || ''
      return acc
    }, {})

    this.ref.set(meals).then(() => {
      this.setState({ editMeals: false })
    })
  }

  render() {

    if (!this.state.meals) {
      return <Spinner text="Загрузка данных о приемах пищи..." />
    }

    return (
      <React.Fragment>

        <div className="page-section">
          <h4>Примерный пищевой рацион за сутки</h4>
          {this.state.editMeals ? (
            <MealsForm
              meals={this.state.meals}
              onSubmit={this.handleSubmitMeals}
            />
          ) : (
            <React.Fragment>
              <MealsList
                meals={this.state.meals}
                onClickEdit={this.handleClickEdit}
              />
              <button type="button" onClick={this.handleClickEdit}>Изменить</button>
            </React.Fragment>
          )}
        </div>

        <div className="page-section">
          <h4>Прочие особенности</h4>
          <PageParams
            set="ration"
            patientKey={this.props.patientKey}
          />
        </div>

      </React.Fragment>
    )
  }

}

export default PageRation
