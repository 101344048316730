import React from 'react'
import firebase from '../firebase'

const useAuth = () => {
  const [checked, setChecked] = React.useState(false)
  const [user, setUser] = React.useState(() => firebase.auth().currentUser)

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      setChecked(true)
      setUser(user)
    })
  }, [])

  const signOut = () => firebase.auth().signOut()

  return React.useMemo(() => {
    return { checked, user, signOut }
  }, [checked, user])
}

export default useAuth
