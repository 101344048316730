import { mapProps, withProps } from 'recompose'

/**
 * HOC that replaces 'config' prop with appropriate 'params' prop
 * @param  {string|function} configSection) Key of config's section
 * @return {component}
 */
export const withParams = mapProps(({ config, groupKey, ...props }) => {
  // if ('function' === typeof configSection) {
  //   configSection = configSection(props)
  // }
  const params = config[groupKey] ? { ...config[groupKey] } : {}

  Object.keys(params).forEach(key => {
    const param = params[key]
    if ('string' === typeof param) {
      params[key] = { title: param, options: null }
    } else {
      params[key] = { title: '[untitled]', options: null, ...param }
    }
  })

  return { ...props, params, groupKey }
})

/**
 * HOC that normalizes 'data' prop containing fetched patient's params
 * @return {component}
 */
export const withNormalizedData = withProps(props => {

  let data = {...props.data}

  Object.keys(data).forEach(key => {
    if ('string' === typeof data[key]) data[key] = { comment: data[key] }
  })

  // reorder them to be as in parameter config
  data = Object.keys(props.params).reduce((ordered, key) => {
    if (key in data) ordered[key] = data[key]
    return ordered
  }, {})

  return { data }
})
