import React from 'react'
import moment from '../moment'
import { withProps } from 'recompose'

import TestValue from './TestValue'
import TestInput from './TestInput'
import SelectRemind from './SelectRemind'

const TestsPendingForm = ({ set, onSubmit }) => (
  <form className="tests-pending-form" onSubmit={onSubmit}>

    <h4>{moment(set.created).format('LL')}</h4>

    <table className="admin-table">

      <thead>
        <tr>
          <th className="value">Пред. рез-т</th>
          <th className="input">Новый рез-т</th>
          <th>Комментарий</th>
        </tr>
      </thead>

      <tbody>
        {set.groups ? set.groups.map(group => (
          <React.Fragment key={group.key}>

            <tr className="group-header">
              <th colSpan={3}>{group.title}</th>
            </tr>

            <TestsPendingForm.Rows tests={group.tests} />

          </React.Fragment>
        )) : (
          <TestsPendingForm.Rows tests={set.tests} />
        )}
      </tbody>

    </table>

    <div className="actions">
      <button type="submit">Сохранить</button>
      <label>
        Дата результатов:
        {' '}
        <input
          type="date"
          name="created"
          defaultValue={moment().format('YYYY-MM-DD')}
        />
      </label>
    </div>

    <input type="hidden" name="set" value={set.created} />

  </form>
)

TestsPendingForm.Rows = ({ tests }) => (
  <React.Fragment>
    {tests.map(test => (
      <React.Fragment key={test.key}>
        <tr>
          <td className="title" colSpan={3}>
            <span>{test.title}</span>
            <SelectRemind name={`reminders[${test.key}]`} />
          </td>
        </tr>
        <tr key={test.created}>
          <td className="value"><TestValue {...test.lastValue} /></td>
          <td className="input"><TestInput name={`values[${test.key}]`} /></td>
          <td>
            <input type="text" className="comment" name={`comments[${test.key}]`} />
          </td>
        </tr>
      </React.Fragment>
    ))}
  </React.Fragment>
)

const enhance = withProps(({ set, groups, tests }) => {

  const setTests = tests.filter(test => {
    return (test.key in set.tests)
  })

  if (!groups) {
    return { set: { ...set, tests: setTests } }
  }

  const groupIndex = groups.reduce((index, group) => {
    index[group.key] = group
    return index
  }, {})

  const setGroupsHash = setTests.reduce((hash, test) => {
    if (!(test.group in hash)) {
      hash[test.group] = {
        ...groupIndex[test.group],
        tests: [ test ]
      }
    }
    else {
      hash[test.group].tests.push(test)
    }
    return hash
  }, {})

  return {
    set: { ...set, tests: setTests, groups: Object.values(setGroupsHash) }
  }
})

export default enhance(TestsPendingForm)
