import React from 'react'
import { Route, Link, useHistory, useRouteMatch } from 'react-router-dom'
import serialize from 'form-serialize'
import ListEditor, { ItemButtons } from '../ListEditor'
import EditStrings from './EditStrings'
import Button from '../ButtonAwesome'
import { saveConfigSection } from '../../hoc/config'
import { withProps } from 'recompose'

const ConfigEditTests = ({ groups, items, sectionGroups, sectionItems }) => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const afterSaveGroups = () => {
    history.push(url)
  }

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    const data = serialize(form, { hash: true })

    // console.log(sectionItems, data) &&
    saveConfigSection(sectionItems, data)
      .then(() => {
        alert('Сохранено!')
      })
      .catch(alert)
  }

  const renderGroupsEditor = () => (
    <React.Fragment>
      <h4>Группы показателей</h4>
      <EditStrings
        section={sectionGroups}
        items={groups}
        afterSave={afterSaveGroups}
      />
    </React.Fragment>
  )

  const renderItemsEditor = () => {
    return (
      <form onSubmit={handleSubmit}>
        {groups ? (
          groups.map(group => (
            <div key={group.key} className="tests-group">
              <h4>{group.text}</h4>
              <ListEditor
                items={group.items}
                itemComponent={withProps({ group })(TestsItem)}
              />
            </div>
          ))
        ) : (
          <ListEditor items={items} itemComponent={TestsItem} />
        )}
        <Button type="submit" text="Сохранить" />
        {groups && (
          <span>
            {' '}
            <Link to={`${url}/groups`}>Редактировать группы</Link>
          </span>
        )}
      </form>
    )
  }

  return (
    <React.Fragment>
      {groups && (
        <Route exact path={`${url}/groups`} render={renderGroupsEditor} />
      )}

      <Route exact path={`${url}`} render={renderItemsEditor} />
    </React.Fragment>
  )
}

const TestsItem = ({ item, actions, group }) => {
  const [showComment, setShowComment] = React.useState(!!item.notes)

  return (
    <div className="params-item">
      <div className="params-item-title">
        <input
          type="text"
          name={`${item.key}[title]`}
          placeholder="Название"
          defaultValue={item.title}
          required={true}
        />

        <label>
          <input
            type="checkbox"
            checked={showComment}
            onChange={e => setShowComment(e.target.checked)}
          />{' '}
          Примечание
        </label>

        {group && (
          <input
            type="hidden"
            name={`${item.key}[group]`}
            defaultValue={group.key}
          />
        )}

        <ItemButtons actions={actions} />
      </div>

      {showComment && (
        <div className="params-item-options">
          <span>Примечание:</span>
          <textarea name={`${item.key}[notes]`} defaultValue={item.notes} />
        </div>
      )}
    </div>
  )
}

export default ConfigEditTests
