import React from 'react'
import { compose, withProps } from 'recompose'
import { withDataTransform } from '../hoc/database'

import Spinner from '../components/Spinner'
import TestsHistoryTable from '../components/TestsHistoryPlain'

class PageTestsHistory extends React.Component {

  state = {}

  render() {

    const { history } = this.props

    if (!history) {
      return <Spinner text="Загрузка истории лабораторных анализов..." />
    }

    if (!this.props.tests.length) {
      return <span className="no-data">(нет истории анализов)</span>
    }

    return (
      <TestsHistoryTable
        tests={this.props.tests}
        groups={this.props.groups}
      />
    )
  }
}

const enhance = compose(

  withDataTransform({
    history: {
      path: props => `${props.refPath}/history`,
      transform: snapshot => {
        const history = snapshot.val() || {}
        Object.keys(history).forEach(key => {

          let items = history[key]
          items = Object.keys(items).map(created => {
            return { ...items[created], created }
          })

          history[key] = items
        })
        return history
      }
    }
  }),

  withProps(props => {

    const history = props.history || {}

    const tests = props.tests
      .filter(test => (test.key in history))
      .map(test => {
        return { ...test, history: history[test.key] }
      })

    if (!props.groups) {
      return { tests }
    }

    const groupIndex = props.groups.reduce((index, group) => {
      index[group.key] = group
      return index
    }, {})

    const groups = tests.reduce((groups, test) => {
      if (!groups[test.group]) {
        groups[test.group] = { ...groupIndex[test.group], tests: [] }
      }
      groups[test.group].tests.push(test)
      return groups
    }, {})

    return { tests, groups: Object.values(groups) }

  })

)

export default enhance(PageTestsHistory)
