import React from 'react';

class PatientEditor extends React.Component {

  handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget
    const data = [ 'name', 'born', 'phone', 'email', 'job' ].reduce((data, name) => {
      data[name] = form[name].value
      return data
    }, {})

    this.props.onSave(data);
    form.reset();

  }

  render() {
    const { name, born, phone, email, job, onCancel } = this.props
    return (
      <div className="patient-editor">
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="name"
            defaultValue={name}
            placeholder="Фамилия, имя, отчество"
            required
          />
          <input
            type="date"
            name="born"
            defaultValue={born}
            placeholder="Дата рождения"
          />
          <input
            type="text"
            name="phone"
            defaultValue={phone}
            placeholder="Телефон"
          />
          <input
            type="email"
            name="email"
            defaultValue={email}
            placeholder="Электропочта"
          />
          <input
            type="text"
            name="job"
            defaultValue={job}
            placeholder="Профессия"
          />
          <div>
            <button type="submit">Сохранить</button>
            <button type="button" onClick={onCancel}>Отменить</button>
          </div>
        </form>
      </div>
    )
  }

}

export default PatientEditor
