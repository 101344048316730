import React, { createFactory } from 'react'
import { lifecycle } from 'recompose'
import firebase from '../firebase'

export const withData = (input, propName = 'data') => (BaseComponent) => {

  const factory = createFactory(BaseComponent)

  return class WithData extends React.Component {

    dataRefs = {}
    listeners = {}

    state = { data: {} }

    createRef = (refPath) => (
      firebase.database().ref(
        'function' === typeof refPath
        ? refPath(this.props)
        : refPath
      )
    )

    componentDidMount() {
      if ('object' !== typeof input) input = { [propName]: input }
      Object.keys(input).forEach(key => {
        const ref = this.dataRefs[key] = this.createRef(input[key])
        this.listeners[key] = ref.on('value', snapshot => {
          const data = { ...this.state.data, [key]: snapshot.val() }
          this.setState({ data })
        })
      })
    }

    componentWillUnmount() {
      Object.keys(this.refs).forEach(key => {
        this.dataRefs[key].off('value', this.listeners[key])
      })
    }

    render() {
      return factory({
        ...this.props,
        ...this.state.data,
        getDataRef: prop => this.dataRefs[prop]
      })
    }
  }
}

export const withDataTransform = input => BaseComponent => {

  let refs = null
  let transformers = null
  let listeners = null

  class WithDataTransform extends React.Component {

    state = Object.keys(input).reduce((acc, key) => {
      acc[key] = null
      return acc
    }, {})

    componentDidMount() {

      refs = Object.keys(input).reduce((acc, key) => {
        const { path } = input[key]
        acc[key] = firebase.database().ref(
          typeof path === 'function' ? path(this.props) : path
        )
        return acc
      }, {})

      transformers = Object.keys(input).reduce((acc, key) => {
        const { transform } = input[key]
        if (transform) {
          acc[key] = transform
        }
        return acc
      }, {})

      listeners = Object.keys(refs).reduce((acc, key) => {
        acc[key] = refs[key].on('value', snapshot => {
          const transform = transformers[key]
          const data = transform ? transform(snapshot, this.props) : snapshot.val()
          this.setState({ [key]: data })
        })
        return acc
      }, {})

    }

    componentWillUnmount() {
      Object.keys(refs).forEach(key => {
        refs[key].off('value', listeners[key])
      })
    }

    render() {
      return <BaseComponent { ...this.props } { ...this.state } />
    }
  }

  return WithDataTransform

}

export const withDataOnce = (refPath) => lifecycle({
  componentDidMount() {
    if ('function' === typeof refPath) {
      refPath = refPath(this.props)
    }
    const readOnce = firebase.database().ref(refPath).once('value')
    readOnce.then(snapshot => {
      this.setState({ data: snapshot.val() })
    })
  }
})
