import React from 'react'
import PropTypes from 'prop-types'
import { compose, branch, renderNothing } from 'recompose'

import { withConfig } from '../hoc/config'
import { withData } from '../hoc/database'
import { withParams, withNormalizedData } from '../hoc/params'


export const normalizeParamGroup = (group) => {
  return Object.keys(group).reduce((normalized, key) => {
    if ('string' === typeof group[key]) {
      normalized[key] = {
        title: group[key],
        options: null,
        commentable: true
      }
    } else {
      normalized[key] = {
        title: group[key].title || '[untitled]',
        options: group[key].options || null,
        commentable: group[key].commentable || true
      }
    }
    return normalized
  }, {})
}

class Params extends React.Component {

  static propTypes = {
    groupKey: PropTypes.string.isRequired,
    patientKey: PropTypes.string.isRequired,
    title: PropTypes.string,
  }


  state = { mode: 'view' }

  onEdit = () => {
    this.setState({ mode: 'edit' })
  }

  onSave = (data) => {

    const setPromise = this.props.getDataRef('data').set(data)
    // const setPromise = this.ref.set(data)

    setPromise.then(() => {
      this.setState({ mode: 'view' })
      console.log('Changed Params mode to: view')
    })

    setPromise.catch((...args) => {
      alert('Sorry! Could not save the data! Check the console for more info.')
      console.error('DB operation failed with arguments:', ...args)
    })

  }

  render() {
    const { params, data } = this.props
    return (
      <div className="params">

        {this.state.mode === 'view' && (
          <ParamGroupList
            params={params}
            data={data}
            onEditClick={this.onEdit}
          />
        )}

        {this.state.mode === 'edit' && (
          <ParamGroupForm
            params={params}
            data={data}
            onSave={this.onSave}
          />
        )}

      </div>
    )
  }

}

const ParamGroupList = ({ params, data = {}, onEditClick }) => {

  const dataKeys = Object.keys(data)
  const dataEmpty = dataKeys.length === 0

  return (
    <div className="param-group-list">
      {dataEmpty && <em>Нет данных</em>}
      <ul className="params">
        {dataKeys.map(key => (
          <li key={key}>
            <span className="param-title">{params[key].title}</span>
            {data[key].option && <span>: <span className="param-option">{data[key].option}</span></span>}
            {data[key].comment && (
              <pre className="param-comment">{data[key].comment}</pre>
            )}
          </li>
        ))}
      </ul>
      {onEditClick && <button onClick={onEditClick}>Изменить</button>}
    </div>
  )

}

class ParamGroupForm extends React.Component {

  static propTypes = {
    params: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired
  }

  state = {
    data: Object.keys(this.props.params).reduce((data, key) => {

      const paramConfig = this.props.params[key]
      const paramData = this.props.data[key] || {}

      const enabled = (key in this.props.data)
      const options = paramConfig.options || []
      const comment = paramData.comment || ''

      data[key] = {
        enabled,
        option: options.length ? (paramData.option || options[0]) : null,
        comment,
        showCommentEditor: comment.length > 0
      }

      return data

    }, {})
  }

  handleFieldChange = (event) => {

    const target = event.currentTarget
    const [ key, prop ] = target.name.split('_')
    const value = target.type === 'checkbox' ? target.checked : target.value

    const data = {...this.state.data}
    data[key][prop] = value

    this.setState({ data })

  }

  saveData = () => {

    const group = this.props.params
    const data = {...this.state.data}

    const packedData = Object.keys(data)
      .filter(key => data[key].enabled)
      .reduce((packed, key) => {
        packed[key] = {
          comment: data[key].comment,
          option: group[key].options ? data[key].option : null
        }
        return packed
      }, {})

    // console.log(packedData)
    this.props.onSave(packedData)

  }

  showCommentEditor = (key) => {
    const data = {...this.state.data}
    data[key].showCommentEditor = true
    this.setState({ data })
  }

  renderParam = (key) => {

    const config = this.props.params[key]
    const param = this.state.data[key]

    const showOptions = param.enabled && config.options
    const showCommentEditor = param.enabled && param.showCommentEditor
    const showCommentButton = param.enabled && !showCommentEditor

    return (
      <li key={key}>

        <span className="param-checkbox">
          <input
            type="checkbox"
            name={`${key}_enabled`}
            id={`${key}-checkbox`}
            checked={param.enabled}
            onChange={this.handleFieldChange}
          />
          {' '}
          <label htmlFor={`${key}-checkbox`}>{config.title}</label>
        </span>

        {showOptions && (
          <span className="param-options">
            :{' '}
            <select
              name={`${key}_option`}
              value={param.option}
              onChange={this.handleFieldChange}
            >{config.options.map((value, index) => (
              <option key={index} value={value}>{value}</option>
            ))}
            </select>
          </span>
        )}

        {showCommentButton && (
          <span className="param-comment-button">
            <button onClick={() => this.showCommentEditor(key)}>+ комментарий</button>
          </span>
        )}

        {showCommentEditor && (
          <div className="param-comment-editor">
            <textarea
              name={`${key}_comment`}
              value={param.comment}
              onChange={this.handleFieldChange}
            />
          </div>
        )}

      </li>
    )
  }

  render() {
    return (
      <div className="param-group-form">
        <ul className="params">
          {Object.keys(this.props.params).map(this.renderParam)}
        </ul>
        <button onClick={this.saveData}>Сохранить</button>
      </div>
    )
  }

}

const enhance = compose(
  withConfig,
  // withParams(({ groupKey }) => groupKey),
  branch(
    props => !props.config,
    renderNothing
  ),
  withParams,
  withData(({ patientKey, groupKey }) => `/params/${patientKey}/${groupKey}`),
  withNormalizedData
)


export default enhance(Params)
