import React from 'react'
import create from 'zustand'
import Spinner from '../components/Spinner'
import firebase from '../firebase'

const DEVMODE = false // process.env.NODE_ENV === 'development'

const initialState = {
  etag: DEVMODE ? 'DEVMODE' : localStorage.getItem('config.etag'),
  data: DEVMODE ? {} : JSON.parse(localStorage.getItem('config.data')),
}

const etagRef = firebase.database().ref('/config/etag')
const dataRef = firebase.database().ref('/config/data')

export const useConfig = create((set, get) => {
  if (DEVMODE) {
    fetch('/config.json')
      .then(res => res.json())
      .then(data => {
        set({ data })
      })
  } else {
    etagRef.on('value', etagSnapshot => {
      const etag = etagSnapshot.val()
      if (etag !== get().etag) {
        dataRef.once('value', dataSnapshot => {
          const data = JSON.parse(dataSnapshot.val())
          set({ etag, data })
        })
      }
    })
  }
  return initialState
})

if (!DEVMODE) {
  useConfig.subscribe(({ etag, data }) => {
    localStorage.setItem('config.etag', etag)
    localStorage.setItem('config.data', JSON.stringify(data))
  })
}

// method for saving configuration and updating etag as well
export const saveConfig = (data, override = false) => {
  if (DEVMODE && !override) {
    return Promise.reject('Saving configuration is disabled in DEVMODE!')
  }

  return new Promise(resolve => {
    dataRef.set(JSON.stringify(data)).then(() => {
      etagRef.set(`etag-${Date.now()}`).then(() => resolve())
    })
  })
}

export const saveConfigSection = (section, data) => {
  const config = useConfig.getState()
  return saveConfig({ ...config.data, [section]: data })
}

export const withConfig = BaseComponent => {
  const WithConfig = props => {
    const config = useConfig()

    if (!config.data) {
      return <Spinner text="Загрузка конфигурации..." />
    }

    return <BaseComponent {...props} config={config.data} />
  }

  return WithConfig
}

export const withConfigSections = input => BaseComponent => {
  const WithConfigSections = props => {
    const config = useConfig()

    if (!config.data) {
      return <Spinner text="Загрузка конфигурации..." />
    }

    const getConfigProps = (input, config) => {
      return Object.keys(input).reduce((configProps, propName) => {
        let { section, transform } = input[propName]

        if (typeof section === 'function') {
          section = section(props)
        }

        section = config[section] || {}
        if (transform && typeof transform === 'function') {
          section = transform(section, props)
        }

        configProps[propName] = section

        return configProps
      }, {})
    }

    return <BaseComponent {...props} {...getConfigProps(input, config.data)} />
  }

  return WithConfigSections
}
