import React from 'react'
import { compose } from 'recompose'
import { withConfigSections } from '../hoc/config'

import { ParamsList } from './ParamSet'

export const FrontParams = ({ data, title }) => (
  <div className="front-section front-section-params">
    {title && <h3>{title}</h3>}
    {data.length ? <ParamsList data={data} /> : <span className="no-data">(нет данных)</span>}
  </div>
)

export class FrontParamsContainer extends React.Component {

  transformData = () => {
    const arrived = this.props.data || {}

    return this.props.params
      .filter(param => (param.key in arrived))
      .map(param => ({ ...param, ...arrived[param.key] }))
  }

  render() {
    const { hideNoData = false, title } = this.props
    const data = this.transformData()

    if (!data.length && hideNoData) {
      return null
    }

    return (
      <FrontParams title={title} data={data} />
    )

  }
}

const enhance = compose(
  withConfigSections({
    params: {
      section: props => props.set,
      transform: section => Object.keys(section).map(key => {
        const value = section[key]
        return typeof value === 'string'
          ? { title: value, options: null, key }
          : { ...value, key }
      })
    }
  })
)

export default enhance(FrontParamsContainer)
