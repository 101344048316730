import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import firebase, { database } from '../firebase'

import Profile from './Profile'
import CardLinks from './CardLinks'
import CardPage from './CardPage'
import CardInfo from './CardInfo'
import PatientEditor from './PatientEditor'
import NotFound from './NotFound'

import pages from '../pages'

class Card extends React.Component {

  state = {
    patient: null
  }

  patientKey = this.props.match.params.patientKey

  savePatientInfo = (data) => {
    const ref = firebase.database().ref(`/patients/${this.patientKey}`)
    ref.set(data).then(() => {
      this.props.history.push(`/card/${this.patientKey}`)
    }).catch(error => {
      alert(error.message)
    })
  }

  removeCard = () => {
    const confirmText = 'Вы уверены, что хотите удалить эту карточку? Это необратимая операция!'
    const { confirm } = window
    if (!confirm(confirmText) || !confirm(confirmText)) {
      return
    }

    const removePaths = [
      `/patients/${this.patientKey}`,
      `/params/${this.patientKey}`,
      `/ration/${this.patientKey}`,
      `/tests/${this.patientKey}`,
      `/writings/${this.patientKey}`,
    ]

    this.props.history.push('/')

    Promise.all(removePaths.map(path => {
      return firebase.database().ref(path).remove()
    })).then(() => {
      alert('Карточка удалена!')
    })
  }

  componentDidMount() {
    this.ref = database.ref(`/patients/${this.patientKey}`)
    this.refListener = this.ref.on('value', snapshot => {
      this.setState({ patient: snapshot.val() })
    })
  }

  componentWillUnmount() {
    this.ref.off('value', this.refListener)
  }

  renderPage = (props) => {

    const { match } = props

    if (!match || !match.params.pageKey) {
      return null
    }

    const page = match && pages[match.params.pageKey]

    if (page && page.component) {

      return (
        <CardPage title={page.title}>
          <page.component
            title={page.title}
            patientKey={this.patientKey}
            pageKey={match.params.pageKey}
            {...props}
          />
        </CardPage>
      )

    }

    return <NotFound />

  }

  render() {

    if (!this.state.patient) {
      return <em>Загрузка данных...</em>
    }

    const patient = this.state.patient
    const { url } = this.props.match

    return (
      <div className="patient-card">
        <nav>
          <Link to={`/front/${this.patientKey}`}>Режим просмотра</Link>
        </nav>
        <div className="patient-card__head">

          <h2>
            <button
              className="fa fa-angle-left"
              onClick={() => this.props.history.push('/')}></button>
            {' '}
            {patient.name}
          </h2>
          <Profile patient={patient} />
        </div>
        <div className="patient-card__body flex-row">
          <CardLinks baseURL={url} />
          <Switch>
            <Route
              path={url}
              exact
              render={props => (
                <CardPage title="Основная информация">
                  <CardInfo
                    {...props}
                    patientKey={this.patientKey}
                    removeCard={this.removeCard}
                  />
                </CardPage>
              )}
            />
            <Route
              path={`${url}/edit`}
              exact
              render={props => (
                <CardPage title="Информация о пациенте">
                  <PatientEditor
                    {...props}
                    {...patient}
                    onSave={this.savePatientInfo}
                    onCancel={() => props.history.push(url)}
                  />
                </CardPage>
              )}
            />
            <Route
              path={`${url}/:pageKey`}
              render={this.renderPage}
            />
          </Switch>
        </div>
      </div>
    )
  }

}

export default Card
