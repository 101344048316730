import React from 'react'
import serialize from 'form-serialize'
import firebase from '../firebase'

import TestsCreateForm from '../components/TestsCreateForm'

class PageTestsCreate extends React.Component {
  state = {}

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const data = serialize(form, { hash: true })

    if (Object.keys(data).length === 0) {
      alert('Невозможно сохранить пустой набор анализов!')
      return
    }

    Object.keys(data).forEach(key => {
      data[key] = { comment: '' }
    })

    const path = `/tests/${this.props.patientKey}/${this.props.testsType}/pending`
    const created = Date.now()

    firebase
      .database()
      .ref(path)
      .child(created)
      .set(data)
      .then(() => {
        form.reset()
        this.props.history.push(this.props.match.url)
      })
  }

  render() {
    return (
      <TestsCreateForm
        tests={this.props.tests}
        groups={this.props.groups}
        lastValues={this.props.lastValues}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

export default PageTestsCreate
