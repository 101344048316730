import React from 'react'
import Params from './Params'


const CardPage = ({ title, children }) => (
  <div className="card-page">
    <div className="card-page__head">
      <h3>{title}</h3>
    </div>
    <div className="card-page__body">
      {children}
    </div>
  </div>
)


export const simpleCardPage = (WrappedComponent) => ({ title, ...props }) => (
  <CardPage title={title}>
    <WrappedComponent {...props} />
  </CardPage>
)

CardPage.createSimpleParamsPage = ({ groupKey }) => ({ patientKey, title }) => (
  <CardPage title={title}>
    <Params {...{ patientKey, groupKey, title }} />
  </CardPage>
)

export default CardPage
