import React from 'react'

import BigTextView from './BigTextView'

const FrontToolTests = ({ texts }) => (
  <div className="front-section front-section-tooltests">

    <h3>Инструментальные исследования</h3>

    <h4>УЗИ щитовидной железы</h4>
    <BigTextView text={texts.uzi_thyroid} />

    <h4>УЗИ брюшной полости</h4>
    <BigTextView text={texts.uzi_abdomen} />

    <h4>УЗИ малого таза</h4>
    <BigTextView text={texts.uzi_pelvic} />

  </div>
)

class FrontToolTestsContainer extends React.Component {

  render() {
    if (!this.props.texts) {
      return null
    }
    return <FrontToolTests texts={this.props.texts} />
  }
}

export default FrontToolTestsContainer
