import React from 'react'
import ListEditorForm from './ListEditorForm'
import { ItemButtons } from '../ListEditor'
import { saveConfigSection } from '../../hoc/config'

class ConfigEditStrings extends React.Component {
  state = {}

  handleSave = data => {
    saveConfigSection(this.props.section, data)
      .then(() => {
        alert('Сохранено!')
        if (this.props.afterSave) {
          this.props.afterSave()
        }
      })
      .catch(alert)
  }

  render() {
    return (
      <ListEditorForm items={this.props.items} onSave={this.handleSave}>
        {(item, index, actions) => (
          <div key={item.key} className="strings-item">
            <input
              type="text"
              name={item.key}
              placeholder="Название"
              defaultValue={item.text}
              required={true}
            />

            <ItemButtons actions={actions} />
          </div>
        )}
      </ListEditorForm>
    )
  }
}

export default ConfigEditStrings
