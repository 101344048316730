import React from 'react'
import pages from '../pages'
import CoolLink from './CoolLink'

const CardLinks = ({ baseURL: url }) => (
  <ul className="card-links">
    <li className="main"><CoolLink exact to={url}>Основная информация</CoolLink></li>
    {Object.keys(pages).map(key => (
      <li key={key}>
        <CoolLink exact={false} to={`${url}/${key}`}>{pages[key].title}</CoolLink>
      </li>
    ))}
  </ul>
)

export default CardLinks
