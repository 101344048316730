import React from 'react'
import { Route } from 'react-router-dom'
import { compose, withProps, branch } from 'recompose'
import { withConfigSections } from '../hoc/config'

import NotFound from '../components/NotFound'
import ConfigLinks from '../components/Config/Links'

import EditStrings from '../components/Config/EditStrings'
import EditParams from '../components/Config/EditParams'
import EditTests from '../components/Config/EditTests'
import EditDiagnosis from '../components/Config/EditDiagnosis'

const createEditorStrings = section =>
  compose(
    withProps({ section }),
    withConfigSections({
      items: {
        section,
        transform: section =>
          Object.keys(section).map(key => ({ key, text: section[key] })),
      },
    }),
  )(EditStrings)

const createEditorParams = section =>
  compose(
    withProps({ section }),
    withConfigSections({
      items: {
        section,
        transform: section =>
          Object.keys(section).map(key => {
            const param = section[key]
            if (typeof param === 'string') {
              return { key, title: param }
            }
            return { key, ...param }
          }),
      },
    }),
  )(EditParams)

const createEditorTests = (sectionItems, sectionGroups) =>
  compose(
    withProps({ sectionItems, sectionGroups }),
    withConfigSections({
      items: {
        section: sectionItems,
        transform: section =>
          Object.keys(section).map(key => {
            const item = section[key]
            if (typeof item === 'string') {
              return { key, title: item, notes: '' }
            }
            return { key, ...item }
          }),
      },
    }),
    branch(
      () => !!sectionGroups,
      withConfigSections({
        groups: {
          section: sectionGroups,
          transform: (section, props) =>
            Object.keys(section).map(key => {
              return {
                key,
                text: section[key],
                items: props.items.filter(item => {
                  if (item.group) {
                    return item.group === key
                  }
                  return (item.group = item.key.split('_')[0]) === key
                }),
              }
            }),
        },
      }),
    ),
  )(EditTests)

const EditorDiagnosis = withConfigSections({
  items: {
    section: 'diagnosis',
    transform: section =>
      Object.keys(section).map(key => {
        return { key, ...section[key] }
      }),
  },
})(EditDiagnosis)

const sections = {
  antropometry: {
    title: 'Антропометрические показатели',
    component: createEditorStrings('antropometry'),
  },
  diseases: {
    title: 'Сопутствующие заболевания',
    component: createEditorParams('diseases'),
  },
  insulin_resistance: {
    title: 'Оценка ИР',
    component: createEditorParams('insulin_resistance'),
  },
  gynae: {
    title: 'Гинекологический анамнез',
    component: createEditorParams('gynae'),
  },
  andrology: {
    title: 'Андрологический анамнез',
    component: createEditorParams('andrology'),
  },
  ration: {
    title: 'Рацион',
    component: createEditorParams('ration'),
  },
  blood_bio: {
    title: 'Биохимический анализ крови',
    component: createEditorTests('blood_tests', 'blood_tests_groups'),
  },
  parasitosis: {
    title: 'Диагностика паразитозов',
    component: createEditorTests('parasitosis'),
  },
  osipov: {
    title: 'Дисбиоз по Осипову',
    component: createEditorTests('dysbiosis_osipov'),
  },
  cortisol_dgea: {
    title: 'Кортизол и ДГЕА в слюне',
    component: createEditorTests('cortisol'),
  },
  amino_acids: {
    title: 'Аминокислоты в моче',
    component: createEditorTests('amino_acids'),
  },
  organics_acids: {
    title: 'Органические кислоты',
    component: createEditorTests('organic_acids', 'organic_acids_groups'),
  },
  blood_cli: {
    title: 'Клинический анализ крови',
    component: createEditorTests('blood_clinic'),
  },
  feces: {
    title: 'Анализы кала',
    component: createEditorTests('feces_tests'),
  },
  diagnosis: {
    title: 'Диагнозы',
    component: EditorDiagnosis,
  },
}

class ScreenConfig extends React.Component {
  state = {}

  renderSection = props => {
    const section = sections[props.match.params.section]

    if (!section) {
      return <NotFound />
    }

    const { component: Component } = section

    if (!Component) {
      return <p>Компонент в разработке</p>
    }

    return (
      <div className="config-section">
        <h3>{section.title}</h3>
        <Component {...props} />
      </div>
    )
  }

  render() {
    const { match } = this.props

    return (
      <div className="screen screen-config">
        <div className="screen-head">
          <h2>Конфигуратор</h2>
        </div>
        <div className="screen-body">
          <div className="screen-body-sidebar">
            <ConfigLinks baseUrl={match.url} sections={sections} />
          </div>
          <div className="screen-body-content">
            <Route path={`${match.url}/:section`} render={this.renderSection} />
          </div>
        </div>
      </div>
    )
  }
}

export default ScreenConfig
