import React from 'react'

class SelectRemind extends React.Component {

  state = { value: this.props.value || '' }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  render() {
    const { value } = this.state
    const { name } = this.props

    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(months => {
      return {
        months,
        text: `${months} мес.`
      }
    })

    return (
      <span className="reminder">
        <i className={`fa fa-bell${!value ? '-o' : ''}`}></i>
        {' '}
        <select name={name} value={value} onChange={this.handleChange}>
          <option value="">нет</option>
          {options.map(option => (
            <option key={option.months} value={option.months}>{option.text}</option>
          ))}
        </select>
      </span>
    )
  }
}

export default SelectRemind
