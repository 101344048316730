import React from 'react'

import PageBigText from './BigText'

const PageToolTests = props => (
  <div className="page page-andrology">
    <h4>УЗИ щитовидной железы:</h4>
    <PageBigText {...props} writing="uzi_thyroid" />

    <h4>УЗИ брюшной полости:</h4>
    <PageBigText {...props} writing="uzi_abdomen" />

    <h4>УЗИ малого таза:</h4>
    <PageBigText {...props} writing="uzi_pelvic" />

    <h4>Денситометрия</h4>
    <PageBigText {...props} writing="densitometry" />

    <h4>Дуплекс сосудов</h4>
    <PageBigText {...props} writing="vasc_duplex" />
  </div>
)

export default PageToolTests
