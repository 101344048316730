import React, { useEffect, useState } from 'react'
import firebase from '../firebase'

import { saveConfig } from '../hoc/config'

const Dev = () => {
  const [config, setConfig] = useState()

  useEffect(() => {
    const dataRef = firebase.database().ref('/config/data')
    dataRef.get().then(snapshot => {
      const json = snapshot.val()
      setConfig(JSON.parse(json))
    })
  }, [])

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    try {
      const data = JSON.parse(form.config.value)
      saveConfig(data, true) // true = override DEV mode
    } catch (error) {
      alert(error.message)
    }
  }

  return (
    <div className="app-page">
      <h2>Конфигурационный файл</h2>
      <p className="warning">
        Соблюдайте осторожность, редактируя этот файл! Не изменяйте коды уже
        существующих параметров. При добавлении нового параметра, убедитесь, что
        его код уникален внутри своей секции.
      </p>
      <form onSubmit={handleSubmit}>
        <p>
          <textarea
            name="config"
            defaultValue={JSON.stringify(config, 0, 2)}
            style={{ width: '100%', height: 500, fontFamily: 'monospace' }}
          />
        </p>
        <button type="submit">Сохранить</button>
      </form>
    </div>
  )
}

export default Dev
