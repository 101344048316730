import { Dialog, styled } from '@mui/material'
import React from 'react'
import HistoryTable from '../Antropometry/History'
import IconButton from '../IconButton'

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: 420,
  },
})

const DialogTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1em',
  background: '#ffc600',
  borderBottom: '1px solid #808080',
})

const DialogContent = styled('div')({
  padding: '1em',
  overflow: 'auto',
})

const Title = styled('div')({
  '& h3': {
    margin: 0,
    lineHeight: 1.2,
  },
})

const TestHistoryDialog = ({ open, onClose, param, history }) => {
  return (
    <StyledDialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Title>
          <div>Полная история измерений:</div>
          <h3>{param?.title}</h3>
        </Title>
        <IconButton icon="times" onClick={() => onClose()} />
      </DialogTitle>
      <DialogContent>
        <HistoryTable history={history} />
      </DialogContent>
    </StyledDialog>
  )
}

export default TestHistoryDialog
