import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import CoolLink from '../components/CoolLink'
import Spinner from '../components/Spinner'
import PageTestsHistory from '../pages/TestsHistory'
import PageTestsPending from '../pages/TestsPending'
import PageTestsCreate from '../pages/TestsCreate'

import { compose, withProps } from 'recompose'
import { withConfig } from '../hoc/config'
import { withDataTransform } from '../hoc/database'

class PageTests extends React.Component {

  refPath = `/tests/${this.props.patientKey}/${this.props.testsType}`

  renderPage = (page) => {

    const { title, component: Component } = PageTests.routes[page]

    return (
      <div className="tests-route-page">
        <h3>{title}</h3>
        {Component && (
          <Component
            {...this.props}
            {...this.state}
            refPath={this.refPath}
          />
        )}
      </div>
    )
  }

  render() {
    const { match, lastValues, pendingSets } = this.props

    if (!lastValues || !pendingSets) {
      return <Spinner text="Загрузка данных..." />
    }

    return (
      <React.Fragment>

        {/* redirect to history tab if user hits root of tests page */}
        <Route
          exact
          path={`${match.url}`}
          render={() => <Redirect to={`${match.url}/history`} />}
        />

        <ul className="tests-links">
          <CoolLink wrapper='li' to={`${match.url}/history`}>История</CoolLink>
          <CoolLink wrapper='li' to={`${match.url}/pending`}>
            Незаполненные
            {pendingSets.length ? <span className="badge">{pendingSets.length}</span> : null}
          </CoolLink>
          <CoolLink wrapper='li' to={`${match.url}/create`}>Назначить новый</CoolLink>
        </ul>
        <div className="tests-page">
          <Route
            exact
            path={`${match.url}/:page`}
            render={({ match }) => this.renderPage(match.params.page)}
          />
        </div>
      </React.Fragment>
    )
  }
}

PageTests.routes = {
  history: {
    title: 'История',
    component: PageTestsHistory
  },
  pending: {
    title: 'Незаполненные',
    component: PageTestsPending
  },
  create: {
    title: 'Назначить новый',
    component: PageTestsCreate
  },
}

const enhance = compose(

  withConfig,

  withDataTransform({

    lastValues: {
      path: props => `/tests/${props.patientKey}/${props.testsType}/last`,
      transform: snapshot => snapshot.val() || {}
    },

    pendingSets: {
      path: props => `/tests/${props.patientKey}/${props.testsType}/pending`,
      transform: snapshot => {
        const sets = []
        snapshot.forEach(child => {
          sets.push({ created: +child.key, tests: child.val() })
        })
        return sets
      }
    }

  }),

  withProps(({ config, testsKey, groupsKey, lastValues }) => {

    let tests = config[testsKey]

    tests = Object.keys(tests).map(key => {
      const test = tests[key]
      if (typeof test === 'string') {
        return { key, title: test, notes: '' }
      }
      return { ...test, key }
    })

    if (lastValues) {
      tests.forEach(test => {
        if (test.key in lastValues) {
          test.lastValue = lastValues[test.key]
        }
      })
    }

    let groups = config[groupsKey]

    if (!groups) {
      return { tests }
    }

    tests.forEach(test => {
      const groupKey = test.key.split('_')[0]
      test.group = groupKey
    })
    groups = Object.keys(groups).map(key => {
      return {
        key,
        title: groups[key],
        tests: tests.filter(test => test.group === key),
      }
    })

    return { tests, groups }

  }),

)

export default enhance(PageTests)
