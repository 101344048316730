import React, { useEffect, useMemo, useState } from 'react'

import firebase from '../firebase'

import Spinner from '../components/Spinner'
import ProcedureItem from '../components/Procedures/Item'
import ProcedureForm from '../components/Procedures/Form'
import moment from 'moment'

const PageProcedures = ({ patientKey }) => {
  const path = `procedures/${patientKey}`

  const [showForm, toggleForm] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    const ref = firebase.database().ref(path)
    const handleValue = snapshot => setData(snapshot.val() ?? {})

    ref.on('value', handleValue)
    return () => ref.off('value', handleValue)
  }, [path])

  const items = useMemo(() => {
    if (!data) return []

    return Object.entries(data)
      .map(([key, val]) => {
        return { id: key, ...val }
      })
      .sort((a, b) => moment(b.date) - moment(a.date))
  }, [data])

  if (!data) {
    return <Spinner text="Загрузка данных о процедурах..." />
  }

  const handleCreate = async item => {
    if (!item.date) {
      alert('Дата обязательна к заполнению!')
      return
    }

    try {
      await firebase.database().ref(path).push(item)
      toggleForm(false)
    } catch (reason) {
      console.error(reason)
      alert('Не удалось создать процедуру!')
    }
  }

  const handleDelete = async item => {
    if (!confirm('Вы уверены, что хотите удалить процедуру?')) {
      return
    }

    try {
      await firebase.database().ref(path).child(item.id).remove()
    } catch (reason) {
      console.error(reason)
      alert('Не удалось удалить процедуру!')
    }
  }

  return (
    <div className="page-procedures">
      <div className="form">
        {showForm ? (
          <ProcedureForm
            onCreate={handleCreate}
            onCancel={() => toggleForm(false)}
          />
        ) : (
          <button onClick={() => toggleForm(true)}>Добавить процедуру</button>
        )}
      </div>
      {items.map(item => (
        <ProcedureItem key={item.id} item={item} onDelete={handleDelete} />
      ))}
    </div>
  )
}

export default PageProcedures
