import React from 'react'
import styled from '@emotion/styled'
import ButtonAwesome from './ButtonAwesome'

const StyledButton = styled(ButtonAwesome)({
  width: '2em',
  height: '2em',
  borderRadius: '50%',
  border: 'none',
  background: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.25)',
  },
})

const IconButton = ({ icon, ...props }) => {
  return <StyledButton icon={icon} {...props} />
}

export default IconButton
