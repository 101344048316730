import React from 'react'
import serialize from 'form-serialize'
import firebase from '../firebase'

import Spinner from '../components/Spinner'
import { ParamsEditor, ParamsEditorList } from '../components/ParamSet'

import { compose, withProps } from 'recompose'
import { withConfig } from '../hoc/config'
import { withDataTransform } from '../hoc/database'

class PageParams extends React.Component {

  state = {
    editMode: this.props.editMode || false
  }

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const data = serialize(form, { hash: true })

    Object.keys(data).forEach(key => {
      const { comment = '', option = null } = data[key]
      data[key] = { comment, option }
    })

    const path = `/params/${this.props.patientKey}/${this.props.set}`
    firebase.database().ref(path).set(data).then(() => {
      this.setState({ editMode: false })
    })
  }

  handleClickEdit = () => {
    this.setState(({ editMode }) => ({ editMode: !editMode }))
  }

  render() {

    if (!this.props.data) {
      return <Spinner text="Загрузка параметров..." />
    }

    if (this.state.editMode) {
      return (
        <ParamsEditor
          params={this.props.params}
          data={this.props.data}
          handleSubmit={this.handleSubmit}
        />
      )
    }

    const { params } = this.props
    const { data: dataHash = {} } = this.props

    const data = params
      .filter(param => { return (param.key in dataHash) })
      .map(param => ({ ...param, ...dataHash[param.key ]}))

    return (
      <ParamsEditorList
        data={data}
        handleClickEdit={this.handleClickEdit}
      />
    )
  }
}

export default compose(

  // fetch flobal configuration
  withConfig,

  // extract and transform the set of parameters
  withProps(props => {
    const paramsHash = props.config[props.set] || {}
    const params = Object.keys(paramsHash).map(key => {
      const param = paramsHash[key]
      if (typeof param === 'string') {
        return { key, title: param, options: null }
      } else {
        return { key, ...param }
      }
    })
    return { params }
  }),

  // fetch data for the current set of parameters
  withDataTransform({
    data: {
      path: props => `params/${props.patientKey}/${props.set}`,
      transform: (snapshot, { params }) => {
        const data = snapshot.val()
        data && Object.keys(params).forEach(key => {
          if (typeof data[key] === 'string') {
            data[key] = { comment: data[key], option: null }
          }
        })
        return data || {}
      }
    }
  }),

)(PageParams)
