import React from 'react'
import firebase from '../firebase'

import BigTextForm from '../components/BigTextForm'
import BigTextView from '../components/BigTextView'
import Spinner from '../components/Spinner'

class PageBigText extends React.Component {

  state = {
    editMode: this.props.editMode || false,
    loaded: false,
    text: null,
  }

  componentDidMount() {
    const path = `/writings/${this.props.patientKey}/${this.props.writing}`

    this.ref = firebase.database().ref(path)
    this.refListener = this.ref.on('value', snapshot => {
      this.setState({ text: snapshot.val(), loaded: true })
    })
  }

  componentWillUnmount() {
    this.ref.off('value', this.refListener)
  }

  handleClickEdit = () => {
    this.setState({ editMode: true })
  }

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const text = form.text.value

    this.ref.set(text).then(() => {
      this.setState({ editMode: false })
    })
  }

  render() {

    if (!this.state.loaded) {
      return <Spinner text="Загрузка данных..." />
    }

    return this.state.editMode ? (
      <div className="big-text">
        <BigTextForm
          text={this.state.text}
          onSubmit={this.handleSubmit}
        />
      </div>
    ) : (
      <div className="big-text">
        {this.state.text ? (
          <BigTextView text={this.state.text} />
        ) : (
          <p>(текст не введен)</p>
        )}
        <button type="button" onClick={this.handleClickEdit}>Изменить</button>
      </div>
    )
  }
}

export default PageBigText
