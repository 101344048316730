import React from 'react'

export const DiagnosisList = ({ data }) => (
  <div className="params-list diagnosis">
    {data.length ? (
      <ul>
        {data.map(param => (
          <li key={param.key}>
            <div>
              <span className="code">{param.code}</span>
              <span>{param.title}</span>
            </div>
            {param.comment && <span className="comment">{param.comment}</span>}
          </li>
        ))}
      </ul>
    ) : (
      <span className="no-data">(не указан)</span>
    )}
  </div>
)

export const DiagnosisEditorList = ({ handleClickEdit, ...props }) => (
  <div>
    <DiagnosisList { ...props } />
    <button onClick={handleClickEdit}>Изменить</button>
  </div>
)

export const DiagnosisEditor = ({ params, data, handleSubmit }) => (
  <form className="params-editor diagnosis" onSubmit={handleSubmit}>
    <ul>
      {params.map(param => (
        <DiagnosisEditor.Item
          key={param.key}
          param={param}
          data={data[param.key]}
        />
      ))}
    </ul>
    <button type="submit">Сохранить</button>
  </form>
)

export const DiagnosisEditorItem = ({ param, data }) => (
  <li>
    <label>
      <input
        type="checkbox"
        name={`${param.key}[enabled]`}
        defaultChecked={!!data}
      />
      <span className="code">{param.code}</span>
      <span className="title">{param.title}</span>
    </label>
    {param.comment && <div className="comment">{param.comment}</div>}
  </li>
)

DiagnosisEditor.Item = DiagnosisEditorItem
