import React from 'react'
import serialize from 'form-serialize'
import ListEditor from '../ListEditor'
import Button from '../ButtonAwesome'

class ListEditorForm extends React.Component {

  state = {}

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const data = serialize(form, { hash: true })

    this.props.onSave(data)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <ListEditor { ...this.props } />
        <Button type="submit" text="Сохранить" />
      </form>
    )
  }
}

export default ListEditorForm
