import React, { useMemo } from 'react'
import ProcedureItem from './Procedures/Item'

const FrontProcedures = ({ data }) => {
  const items = useMemo(() => {
    if (!data) return []

    return Object.entries(data)
      .map(([key, val]) => {
        return { id: key, ...val }
      })
      .sort((a, b) => b.date - a.date)
  }, [data])

  if (!items.length) {
    return <span className="no-data">(нет данных)</span>
  }

  return (
    <div className="front-procedures">
      {items.map((item, index) => (
        <ProcedureItem key={index} item={item} />
      ))}
    </div>
  )
}

export default FrontProcedures
