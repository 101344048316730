import React, { useReducer } from 'react'

const ProcedureForm = ({ onCreate, onCancel }) => {
  const [values, update] = useReducer(
    (state, update) => ({ ...state, ...update }),
    {},
  )

  const handleSubmit = e => {
    e.preventDefault()
    onCreate(values)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="procedure-form">
        <div className="header">
          <div>
            <label>Дата:</label>
            <input
              type="date"
              required
              value={values.date}
              onChange={e => update({ date: e.target.value })}
            />
          </div>
          <div>
            <label>Медсестра:</label>
            <input
              type="text"
              value={values.nurse}
              onChange={e => update({ nurse: e.target.value })}
            />
          </div>
        </div>

        <label>Выполненное назначение:</label>
        <textarea
          required
          rows={5}
          value={values.whats_done}
          onChange={e => update({ whats_done: e.target.value })}
        />

        <label>Комментарий:</label>
        <textarea
          rows={3}
          value={values.comments}
          onChange={e => update({ comments: e.target.value })}
        />
      </div>

      <div className="buttons">
        <button type="submit">Создать</button>
        <button type="button" onClick={() => onCancel()}>
          Отмена
        </button>
      </div>
    </form>
  )
}

export default ProcedureForm
