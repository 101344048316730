import React from 'react'
import TestValue from './TestValue'

const TestsCreateForm = ({ groups, tests, lastValues, onSubmit }) => {
  const groupsByKey = React.useMemo(() => {
    return groups
      ? Object.fromEntries(groups.map(group => [group.key, group]))
      : {}
  }, [groups])

  const [selected, setSelected] = React.useState(() => {
    if (groups) {
      return groups.reduce((result, group) => {
        return {
          ...result,
          ...Object.fromEntries(group.tests.map(test => [test.key, false])),
        }
      }, {})
    } else {
      return Object.fromEntries(tests.map(test => [test.key, false]))
    }
  })

  const toggleTest = e => {
    const key = e.target.name
    setSelected({ ...selected, [key]: !selected[key] })
  }

  const selectedGroups = React.useMemo(() => {
    if (groups) {
      return Object.fromEntries(
        groups.map(group => [
          group.key,
          group.tests.every(test => selected[test.key]),
        ]),
      )
    } else {
      return {}
    }
  }, [groups, selected])

  const toggleGroupOfTests = key => {
    setSelected({
      ...selected,
      ...Object.fromEntries(
        groupsByKey[key].tests.map(test => [test.key, !selectedGroups[key]]),
      ),
    })
  }

  const selectedAll = React.useMemo(() => {
    return Object.values(selected).every(Boolean)
  }, [selected])

  const toggleSelectedAll = () => {
    setSelected(
      Object.fromEntries(Object.keys(selected).map(key => [key, !selectedAll])),
    )
  }

  return (
    <form onSubmit={onSubmit} className="tests-create-form">
      <table className="admin-table">
        <thead>
          <tr>
            <th>
              <label>
                <input
                  type="checkbox"
                  checked={selectedAll}
                  onChange={toggleSelectedAll}
                />
                <span className="spring">Показатель</span>
              </label>
            </th>
            <th className="value">Пред. рез-т</th>
          </tr>
        </thead>

        <tbody>
          {groups ? (
            groups.map(group => (
              <React.Fragment key={group.key}>
                <tr className="group-header">
                  <th colSpan={2}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedGroups[group.key]}
                        onChange={() => toggleGroupOfTests(group.key)}
                      />
                      <span className="spring">{group.title}</span>
                    </label>
                  </th>
                </tr>
                <TestsCreateForm.List
                  tests={group.tests}
                  lastValues={lastValues}
                  selectedTests={selected}
                  onToggleTest={toggleTest}
                />
              </React.Fragment>
            ))
          ) : (
            <TestsCreateForm.List
              tests={tests}
              lastValues={lastValues}
              selectedTests={selected}
              onToggleTest={toggleTest}
            />
          )}
        </tbody>
      </table>
      <button type="submit">Сохранить</button>
    </form>
  )
}

TestsCreateForm.List = ({ tests, selectedTests, onToggleTest }) => (
  <React.Fragment>
    {tests.map(test => (
      <tr key={test.key}>
        <td>
          <label>
            <input
              type="checkbox"
              name={test.key}
              checked={selectedTests[test.key]}
              onChange={onToggleTest}
            />
            <span>{test.title}</span>
            {test.notes && <span className="notes">({test.notes})</span>}
          </label>
        </td>
        <td className="value">
          <TestValue {...test.lastValue} />
        </td>
      </tr>
    ))}
  </React.Fragment>
)

export default TestsCreateForm
