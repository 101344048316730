import moment from 'moment'
import React from 'react'

const ProcedureItem = ({ item, onDelete }) => {
  return (
    <div className="procedure-item">
      <div className="header">
        <div>
          <label>Дата:</label> {moment(item.date).format('L')}
        </div>
        <div>
          <label>Медсестра:</label> {item.nurse}
        </div>
        {onDelete && (
          <button type="button" onClick={() => onDelete(item)}>
            <i className="fa fa-remove"></i>
          </button>
        )}
      </div>

      <label>Выполненное назначение:</label>
      <div>{item.whats_done}</div>

      {item.comments && (
        <>
          <label>Комментарий:</label>
          <div>{item.comments}</div>
        </>
      )}
    </div>
  )
}

export default ProcedureItem
