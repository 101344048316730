import React from 'react'
import { Link } from 'react-router-dom'
import { database } from '../firebase'

import PatientEditor from './PatientEditor'

class PatientPicker extends React.Component {

  state = {
    query: '',
    patients: [],
    loading: true,
    showAddForm: false
  }

  componentDidMount() {
    this.ref = database.ref('/patients')
    this.refListener = this.ref.orderByChild('name').on('value', snapshot => {

      const patients = []
      snapshot.forEach(child => {
        patients.push({ ...child.val(), key: child.key })
      })

      this.setState({ patients, loading: false });

    });
  }

  componentWillUnmount() {
    this.ref.off('value', this.refListener)
  }

  filterPatients = () => (
    this.state.patients.filter(info => {
      const regexp = new RegExp(this.state.query, 'i')
      return regexp.test(info.name)
    })
  )

  createPatient = (patient) => {

    const { key } = this.ref.push()

    this.ref.child(key).set(patient).then(() => {
      console.log('Saved patient!', key)
      this.setState({ showAddForm: false })
      this.props.history.replace(`/card/${key}`)
    });

  }

  queryChange = (event) => {
    const query = event.currentTarget.value
    this.setState({ query })
  }

  renderPatient = (card) => (
    <li key={card.key}>
      <i className="icon fa fa-user-circle"></i>
      {' '}
      <Link to={`/front/${card.key}`}>{card.name}</Link>
    </li>
  )

  render() {

    const showPatients = this.filterPatients()

    return (
      <div className="narrow patient-picker">

        <input
          type="text"
          className="patient-picker__search"
          placeholder="Поиск пациента..."
          value={this.state.query}
          onChange={this.queryChange}
        />

        <div className="patient-picker__result">
          {showPatients.length ? (
            <ul className="patient-picker-list">
              {showPatients.map(this.renderPatient)}
            </ul>
          ) : (this.state.loading) ? null : (
            <p>
              Ничего не найдено. Может, это
              {' '}
              <button
                style={{ fontSize: '1em' }}
                onClick={() => this.setState({ showAddForm: true })}>
                новый пациент?
              </button>
            </p>
          )}
        </div>

        {this.state.showAddForm ? (
          <PatientEditor
            name={this.state.query}
            onSave={this.createPatient}
            onCancel={() => this.setState({ showAddForm: false })}
          />
        ) : null}

      </div>
    )
  }

}

export default PatientPicker
