import React from 'react'
import { withStateHandlers } from 'recompose'

const MealsForm = ({ meals, removeMeal, addMeal, handleSubmit }) => (
  <form onSubmit={handleSubmit}>

    <p className="tip">Обратите внимание, что при редактировании списка порядок строк не важен. При сохранении они будут отсортированы по времени приемов пищи. Приемы пищи, где не указано время, не сохраняются.</p>

    <table className="meals">

      <thead>
        <tr>
          <th className="index">№</th>
          <th className="time">Время</th>
          <th>Перечень блюд</th>
          <th className="actions"></th>
        </tr>
      </thead>

      <tbody>
        {meals.map((meal, index) => (
          <tr key={meal.key}>
            <td className="index">{index + 1}</td>
            <td className="time">
              <input
                type="time"
                name={`meals[${index}][time]`}
                defaultValue={meal.time}
              />
            </td>
            <td>
              <textarea
                className="comment"
                name={`meals[${index}][desc]`}
                defaultValue={meal.desc}
              />
            </td>
            <td className="actions">
              {meals.length > 1 && (
                <button type="button" onClick={() => removeMeal(index)}>
                  <i className="fa fa-remove"></i>
                </button>
              )}
              {index === meals.length - 1 && (
                <button type="button" onClick={addMeal}>
                  <i className="fa fa-plus"></i>
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>

    </table>
    <button type="submit">Сохранить</button>
  </form>
)

const enhance = withStateHandlers(

  props => {
    const meals = [...(props.meals || []), {}, {}, {}, {}, {}].slice(0, 5)
    meals.forEach(meal => { meal.key = Math.random() })
    return { meals }
  },

  {
    addMeal: (state) => () => {
      const meals = [...state.meals]
      meals.push({ key: Math.random() })
      return { meals }
    },
    removeMeal: (state) => (index) => {
      const meals = [...state.meals]
      meals.splice(index, 1)
      return { meals }
    },
    handleSubmit: (state, { onSubmit }) => event => {
      event.preventDefault()
      onSubmit(event.target)
    }
  }
)

export default enhance(MealsForm)
