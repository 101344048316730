import React, { useMemo } from 'react'
import { useConfig } from '../hoc/config.js'
import { useDialog } from '../hooks/useDialog'
import moment from '../moment'
import { useAntropometryParams } from '../pages/Antropometry'
import TestHistoryDialog from './Dialogs/TestHistoryDialog'
import IconButton from './IconButton'
import styled from '@emotion/styled'

const Flex = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const Button = styled(IconButton)({
  width: '1.5em',
  height: '1.5em',
  '& .fa': { fontSize: '0.75em' },
  '@media print': { display: 'none' },
})

export const FrontAntropometry = ({ data, numTestColumns = 5 }) => {
  const config = useConfig(state => state.data)

  const params = useAntropometryParams(config)

  const allTests = useMemo(() => {
    if (!data) return []

    return Object.entries(data).map(([ts, values]) => {
      return {
        date: moment(+ts).format('L'),
        values,
      }
    })
  }, [data])

  const visibleTests = useMemo(() => {
    return allTests.slice(-numTestColumns)
  }, [allTests, numTestColumns])

  const visibleParams = useMemo(() => {
    return params.filter(param =>
      visibleTests.some(test => param.value(test.values)),
    )
  }, [visibleTests, params])

  const testColumns = visibleTests
    .concat(Array(numTestColumns).fill({}))
    .slice(0, numTestColumns)

  const historyDialog = useDialog()

  const showHistoryDialog = param => {
    const history = allTests.map(test => {
      return { date: test.date, value: param.value(test.values) }
    })
    historyDialog.open({ param, history })
  }

  if (!data) {
    return <span className="no-data">(нет даннных)</span>
  }

  return (
    <div className="front-section front-section-antropometry">
      <table>
        <thead>
          <tr>
            <th>Показатель</th>
            {testColumns.map((test, index) => (
              <th key={index} className="value">
                {test.date ?? null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {visibleParams.map(param => (
            <tr key={param.code}>
              <td>
                <Flex>
                  {param.title}
                  {allTests.length > visibleTests.length && (
                    <Button
                      icon="list"
                      onClick={() => showHistoryDialog(param)}
                    />
                  )}
                </Flex>
              </td>
              {testColumns.map((test, index) => (
                <td key={index} className="value">
                  {param.value(test.values)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <TestHistoryDialog
        {...historyDialog.state}
        onClose={historyDialog.close}
      />
    </div>
  )
}

export default FrontAntropometry
