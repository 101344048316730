import React from 'react'
import { compose } from 'recompose'
import { withConfigSections } from '../hoc/config'

const FrontDiagnosis = ({ data, title }) => (
  <div className="front-section front-section-diagnosis">
    {title && <h3>{title}</h3>}
    {data.length ? <FrontDiagnosis.List data={data} /> : <span className="no-data">(нет данных)</span>}
  </div>
)

FrontDiagnosis.List = ({ data }) => (
  <ul>
    {data.map(item => (
      <li key={item.key}>
        <div>
          <span className="code">{item.code}</span>
          {' '}
          <span className="title">{item.title}</span>
        </div>
        {item.comment && <span className="comment">{item.comment}</span>}
      </li>
    ))}
  </ul>
)

const enhance = compose(
  withConfigSections({
    data: {
      section: props => props.set,
      transform: (section, { data }) => {
        const params = Object.keys(section).map(key => {
          const value = section[key]
          return { ...value, key }
        })
        return params
          .filter(param => (param.key in data))
          .map(param => ({ ...data[param.key], ...param }))
      }
    }
  })
)

export default enhance(FrontDiagnosis)
