import React from 'react'
import { compose } from 'recompose'
import { withConfigSections } from '../hoc/config'

import { ParamsList } from './ParamSet'

const FrontAndrology = ({ questionnaire, params, andrology }) => (
  <div className="front-section front-section-andrology">
    <h3>Андрологический анамнез</h3>

    {questionnaire && (
      <React.Fragment>
        <h4>По опроснику у М:</h4>
        <pre className="comment">{questionnaire}</pre>
      </React.Fragment>
    )}

    <h4>Оценка AД</h4>
    {params ? (
      <ParamsList data={params} />
    ) : (
      <span className="no-data">(нет данных)</span>
    )}

    <h4>Изменение массы в течение жизни</h4>
    {andrology ? (
      <pre className="comment">{andrology}</pre>
    ) : (
      <span className="no-data">(нет данных)</span>
    )}

  </div>
)

class FrontAndrologyContainer extends React.Component {

  transformParams() {
    const arrived = this.props.params || {}

    return this.props.paramsConfig
      .filter(param => (param.key in arrived))
      .map(param => ({ ...param, ...arrived[param.key] }))
  }

  render() {

    const { massHistory, questionnaire } = this.props
    const params = this.transformParams()

    return (
      <FrontAndrology
        questionnaire={questionnaire}
        params={params}
        andrology={massHistory}
      />
    )
  }
}

const enhance = compose(
  withConfigSections({
    paramsConfig: {
      section: 'andrology',
      transform: section => Object.keys(section).map(key => {
        const value = section[key]
        return typeof value === 'string'
          ? { title: value, options: null, key }
          : { ...value, key }
      })
    }
  })
)

export default enhance(FrontAndrologyContainer)
