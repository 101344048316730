import React from 'react'
import moment from '../moment'

const formatAge = born => {
  const localeData = moment.localeData();
  const ageYears = moment.duration(moment().diff(moment(born))).get('years');
  return localeData.relativeTime(ageYears, false, 'yy');
}

const FrontProfile = ({ name, born, phone, email, job }) => (
  <div className="front-profile">

    <table>
      <tbody>
        <tr>
          <th>Ф.И.О.</th>
          <td>{name}</td>
        </tr>
        <tr>
          <th>Год рождения</th>
          <td>
            {born ? (
              <span>
                {moment(born).format('YYYY')}
                {' '}
                <span className="age">({formatAge(born)})</span>
              </span>
            ) : '(не указан)'}
          </td>
        </tr>
        <tr>
          <th>Телефон</th>
          <td>{phone || '(не указан)'}</td>
        </tr>
        <tr>
          <th>Эл. почта</th>
          <td>{email || '(не указана)'}</td>
        </tr>
        <tr>
          <th>Профессия</th>
          <td>{job || '(не указана)'}</td>
        </tr>
      </tbody>
    </table>

  </div>
)

export default FrontProfile
