import React from 'react'
import { Redirect } from 'react-router-dom'

import firebase from '../firebase'

const Login = ({ location, user, authChecked }) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const handleSubmit = event => {
    event.preventDefault()
    firebase.auth().signInWithEmailAndPassword(email, password)
  }

  const handleClickForgot = event => {
    event.preventDefault()

    if (!email.length) {
      alert('Введите адрес электронной почты!')
      return
    }

    const errors = {
      'auth/user-not-found':
        'Пользователя с таким адресом электронной почты не существует!',
      'auth/invalid-email': 'Некорректный формат адреса электронной почты!',
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        alert(`Ссылка для сброса пароля успешно отправлена на ${email}`)
      })
      .catch(error => {
        const message = errors[error.code] || error.message
        alert(message)
        console.error(error)
      })
  }

  const { from } = location.state || { from: { pathname: '/' } }

  return (
    <div className="app-page login">
      {user ? (
        <Redirect to={from} />
      ) : authChecked ? (
        <div className="inner">
          <p>
            Для использования приложения необходимо авторизоваться. Обратите
            внимание, что это не релизная версия приложения, поэтому данные,
            показываемые и редактируемые внутри него, являются общими для всех
            авторизованных пользователей. Релизная версия предусматривает доступ
            авторизованного пользователя только к своей ветке хранилища.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="narrow flex-column login-form">
              <input
                type="text"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <input
                type="password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <div style={{ textAlign: 'center' }}>
                <button>Войти</button>
                {' или '}
                <a href="#forgot" onClick={handleClickForgot}>
                  забыли пароль?
                </a>
              </div>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  )
}

export default Login
