import React from 'react'
import serialize from 'form-serialize'
import firebase from '../firebase'

import Spinner from '../components/Spinner'
import { DiagnosisEditor, DiagnosisEditorList } from '../components/Diagnosis'

import { compose, withProps } from 'recompose'
import { withConfigSections } from '../hoc/config'
import { withDataTransform } from '../hoc/database'

class PageDiagnosis extends React.Component {

  state = {
    editMode: this.props.editMode || false
  }

  handleSubmit = event => {
    event.preventDefault()

    const form = event.target
    const data = serialize(form, { hash: true })

    Object.keys(data).forEach(key => {
      const { comment = '', option = null } = data[key]
      data[key] = { comment, option }
    })

    const path = `/params/${this.props.patientKey}/${this.props.set}`
    firebase.database().ref(path).set(data).then(() => {
      this.setState({ editMode: false })
    })
  }

  handleClickEdit = () => {
    this.setState(({ editMode }) => ({ editMode: !editMode }))
  }

  render() {

    if (!this.props.data) {
      return <Spinner />
    }

    if (this.state.editMode) {
      return (
        <DiagnosisEditor
          params={this.props.params}
          data={this.props.data}
          handleSubmit={this.handleSubmit}
        />
      )
    }

    const { params } = this.props
    const { data: dataHash = {} } = this.props

    const data = params
      .filter(param => { return (param.key in dataHash) })
      .map(param => ({ ...dataHash[param.key], ...param }))

    return (
      <DiagnosisEditorList
        data={data}
        handleClickEdit={this.handleClickEdit}
      />
    )
  }
}

export default compose(

  withProps({ set: 'diagnosis' }),

  withConfigSections({
    params: {
      section: props => props.set,
      transform: section => {
        return Object.keys(section).map(key => ({ ...section[key], key }))
      }
    }
  }),

  // fetch data for the current set of parameters
  withDataTransform({
    data: {
      path: props => `params/${props.patientKey}/${props.set}`,
      transform: snapshot => (snapshot.val() || {}),
    },
  }),

)(PageDiagnosis)
