import React from 'react'

import Button from './ButtonAwesome'

const AuthButtons = ({ user, signOut }) => {
  const handleClickSignOut = e => {
    e.preventDefault()
    signOut()
  }

  return (
    <div className="auth-buttons">
      {user && (
        <span>
          {user.email}{' '}
          <Button
            className="yellow-button"
            icon="sign-out"
            onClick={handleClickSignOut}
          />
        </span>
      )}
    </div>
  )
}

export default AuthButtons
