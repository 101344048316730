import React from 'react'
import ListEditorForm from './ListEditorForm'
import { ItemButtons } from '../ListEditor'
import { saveConfigSection } from '../../hoc/config'

class ConfigEditDiagnosis extends React.Component {
  state = {}

  handleSave = data => {
    // console.log(JSON.stringify(data, 0, 2))
    saveConfigSection('diagnosis', data)
      .then(() => alert('Сохранено!'))
      .catch(alert)
  }

  render() {
    return (
      <ListEditorForm
        items={this.props.items}
        itemComponent={ConfigEditDiagnosisItem}
        onSave={this.handleSave}
      />
    )
  }
}

class ConfigEditDiagnosisItem extends React.Component {
  state = {
    showComment: !!this.props.item.comment,
  }

  handleChangeShowComment = event => {
    this.setState({ showComment: event.target.checked })
  }

  render() {
    const { item, actions } = this.props

    return (
      <div className="params-item">
        <div className="params-item-title">
          <input
            type="text"
            className="diagnosis-code"
            name={`${item.key}[code]`}
            placeholder="Код"
            defaultValue={item.code}
          />

          <input
            type="text"
            className="diagnosis-title"
            name={`${item.key}[title]`}
            placeholder="Название"
            defaultValue={item.title}
            required={true}
          />

          <label>
            <input
              type="checkbox"
              checked={this.state.showComment}
              onChange={this.handleChangeShowComment}
            />{' '}
            Комментарий
          </label>

          <ItemButtons actions={actions} />
        </div>

        {this.state.showComment && (
          <div className="params-item-options">
            <span>Комментарий:</span>
            <textarea
              name={`${item.key}[comment]`}
              defaultValue={item.comment}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ConfigEditDiagnosis
