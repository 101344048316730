import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

const config = {
  apiKey: 'AIzaSyBUiroeswSXOqLls99o98BI9l5olTpw1y4',
  authDomain: 'doctor-cards.firebaseapp.com',
  databaseURL: 'https://doctor-cards.firebaseio.com',
  projectId: 'doctor-cards',
  storageBucket: '',
  messagingSenderId: '105148640373',
}

firebase.initializeApp(config)

export const database = firebase.database()
export const auth = firebase.auth

auth().languageCode = 'ru'

export default firebase
