import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'

import logo from './logo.svg'
import './css/style.css'

import AuthButtons from './components/AuthButtons'
import ProtectedRoute from './components/ProtectedRoute'
import Login from './components/Login'
import PatientPicker from './components/PatientPicker'
import Card from './components/Card'
import ScreenFront from './screens/ScreenFront'
import NotFound from './components/NotFound'
import Config from './components/Config'
import ScreenConfig from './screens/ScreenConfig'
import useAuth from './hooks/useAuth'

const App = () => {
  const auth = useAuth()
  return (
    <div className="app">
      <header>
        <div className="inner flex-row">
          <img src={logo} className="logo" alt="logo" />
          <h1 style={{ flex: 1 }}>
            <Link className="home" to="/">
              <span>Doctor</span>
              <span className="yellow">Cards</span>
            </Link>
          </h1>
          {auth.user && (
            <>
              <AuthButtons user={auth.user} signOut={auth.signOut} />
              <Link className="yellow-button fa fa-cog" to="/config" />
            </>
          )}
        </div>
      </header>
      <section className="stretch">
        {auth.checked && (
          <div className="inner">
            <Switch>
              <ProtectedRoute
                auth={auth}
                path="/config"
                component={ScreenConfig}
              />
              <ProtectedRoute
                auth={auth}
                path="/config-json"
                component={Config}
              />
              <Route
                exact
                path="/login"
                render={routeProps => (
                  <Login
                    {...routeProps}
                    user={auth.user}
                    authChecked={auth.checked}
                  />
                )}
              />
              <ProtectedRoute
                exact
                auth={auth}
                path="/"
                component={PatientPicker}
              />
              <ProtectedRoute
                auth={auth}
                path="/card/:patientKey"
                component={Card}
              />
              <ProtectedRoute
                auth={auth}
                path="/front/:patientKey"
                component={ScreenFront}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        )}
      </section>
      {/*
    <footer>
      <div className="inner">
        &copy; 2018 East Empire Trade Company
      </div>
    </footer>
    */}
    </div>
  )
}

export default App
