import React from 'react'
import moment from '../moment'

import { compose, withProps } from 'recompose'
import { withConfig } from '../hoc/config'

const FrontLabTests = ({ title, groups, tests, values }) => (
  <div className="front-section front-section-labtests">
    {title && <h4>{title}</h4>}
    <table>
      <thead>
        <tr>
          <th>Показатель</th>
          <th className="date">Дата</th>
          <th className="value">Значение</th>
        </tr>
      </thead>

      <tbody>
        {groups ? (
          groups.map(group => (
            <React.Fragment key={group.key}>
              <tr className="group-span">
                <th colSpan={3}>{group.title}</th>
              </tr>
              <FrontLabTests.Rows tests={group.tests} values={values || {}} />
            </React.Fragment>
          ))
        ) : (
          <FrontLabTests.Rows tests={tests} values={values || {}} />
        )}
      </tbody>
    </table>
  </div>
)

FrontLabTests.Rows = ({ tests, values }) => {
  const calcAlbuminPercentage = values => {
    const alb = +values['ALB_ALB']?.value.replace(',', '.')
    const pro = +values['ALB_PRO']?.value.replace(',', '.')
    return alb > 0 && pro > 0 ? (
      <span style={{ fontSize: 'smaller' }}>
        <br />({((alb / pro) * 100).toFixed(2)}%)
      </span>
    ) : (
      ''
    )
  }

  return (
    <React.Fragment>
      {tests.map(test => (
        <tr key={test.key} className={!values[test.key] ? 'no-data' : ''}>
          <td className="title">{test.title}</td>
          <td className="date">
            {values[test.key] && moment(values[test.key].created).format('L')}
          </td>
          <td className="value">
            {values[test.key] && values[test.key].value}
            {test.key === 'ALB_ALB' && calcAlbuminPercentage(values)}
          </td>
        </tr>
      ))}
    </React.Fragment>
  )
}

class FrontLabTestsContainer extends React.Component {
  render() {
    if (!Object.keys(this.props.values).length) {
      return <span className="no-data">(нет данных)</span>
    }
    return <FrontLabTests {...this.props} />
  }
}

export default compose(
  withConfig,

  withProps(props => {
    const testsHash = props.config[props.testsSection]
    const groupHash = props.config[props.groupSection]

    if (!testsHash) {
      console.log(
        'FrontLabTests - Invalid property value: testsSection =',
        props.testsSection,
      )
    }

    const tests = Object.keys(testsHash)
      .map(key => {
        const test = testsHash[key]
        return typeof test === 'string'
          ? { key, title: test, notes: null }
          : { ...test, key }
      })
      .filter(test => {
        return !!props.values[test.key]
      })

    if (!groupHash) {
      return { tests, testsHash }
    }

    tests.forEach(test => {
      const key = (test.groupKey = test.key.split('_')[0])
      test.groupTitle = groupHash[key]
    })

    const groups = Object.keys(groupHash)
      .map(key => {
        const group = {
          key,
          title: groupHash[key],
          tests: tests.filter(test => test.groupKey === key),
        }
        return group
      })
      .filter(group => {
        return group.tests.length
      })

    return { tests, testsHash, groups }
  }),
)(FrontLabTestsContainer)
