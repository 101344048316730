import React from 'react'

const BigTextForm = ({ text, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <textarea
      className="comment big-comment"
      name="text"
      defaultValue={text}
    />
    <button type="submit">Сохранить</button>
  </form>
)

export default BigTextForm
