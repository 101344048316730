import React from 'react'
import { styled } from '@mui/material'

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse',
  '& td': {
    padding: '4px 8px',
    border: '1px solid #808080',
  },
  '& td.value': {
    textAlign: 'center',
  },
})

const HistoryTable = ({ history }) => {
  return (
    <StyledTable>
      <tbody>
        {history.map(test => (
          <tr key={test.date}>
            <td>{test.date}</td>
            <td className="value">{test.value}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

export default HistoryTable
