import React from 'react'
import Button from './ButtonAwesome'

const swap = (arr, a, b) => {
  const tmp = arr[a]
  arr[a] = arr[b]
  arr[b] = tmp
}

class ListEditor extends React.Component {

  state = {
    items: this.props.items ? [...this.props.items] : []
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.items) {
      this.setState({ items: nextProps.items })
    }
  }

  addItem = () => {
    const newItem = { key: Date.now().toString(36) }
    const items = [ ...this.state.items, newItem ]
    this.setState({ items })
  }

  removeItem = index => () => {

    const items = [ ...this.state.items ]
    const confirmText = 'Вы уверены, что хотите удалить этот элемент?'

    if (index >= 0 && index < items.length && window.confirm(confirmText)) {
      items.splice(index, 1)
      this.setState({ items })
    }
  }

  moveItemUp = index => () => {

    const items = [ ...this.state.items ]

    if (index > 0 && index < items.length) {
      swap(items, index, index - 1)
      this.setState({ items })
    }
  }

  moveItemDown = index => () => {

    const items = [ ...this.state.items ]

    if (index >= 0 && index < items.length - 1) {
      swap(items, index, index + 1)
      this.setState({ items })
    }
  }

  renderItem = (item, index) => {

    const itemActions = {
      remove: this.removeItem(index),
      moveUp: this.moveItemUp(index),
      moveDown: this.moveItemDown(index)
    }

    if (typeof this.props.children === 'function') {
      return this.props.children(
        item,
        index,
        itemActions
      )
    }

    const { itemComponent: Component } = this.props

    return (
      <Component
        key={item.key}
        item={item}
        index={index}
        actions={itemActions}
      />
    )
  }

  render() {
    return (
      <div className="list-editor">

        <div className="list-editor-items">
          {this.state.items.map(this.renderItem)}
        </div>

        <div className="list-editor-actions">
          <Button icon="plus" onClick={this.addItem} />
        </div>

      </div>
    )
  }
}

export const ItemButtons = ({ actions }) => (
  <span className="list-editor-item-actions">
    <Button icon="caret-up" onClick={actions.moveUp} />
    <Button icon="caret-down" onClick={actions.moveDown} />
    <Button icon="remove" onClick={actions.remove} />
  </span>
)

export default ListEditor
