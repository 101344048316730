import React from 'react'
import { Link } from 'react-router-dom'

import Spinner from '../components/Spinner'
import FrontProfile from '../components/FrontProfile'
import FrontAntropometry from '../components/FrontAntropometry'
import FrontParams from '../components/FrontParams'
import FrontAndrology from '../components/FrontAndrology'
import FrontRation from '../components/FrontRation'
import FrontToolTests from '../components/FrontToolTests'
import FrontLabTests from '../components/FrontLabTests'
import FrontDiagnosis from '../components/FrontDiagnosis'
import FrontProcedures from '../components/FrontProcedures'

import firebase from '../firebase'
import FrontConsultations from '../components/FrontConsultations'

const fetchData = paths => {
  const data = {}
  const props = Object.keys(paths)
  return Promise.all(
    props.map(prop => {
      const ref = firebase.database().ref(paths[prop])
      return ref.once('value').then(snapshot => (data[prop] = snapshot.val()))
    }),
  ).then(() => data)
}

class ScreenFront extends React.Component {
  state = { data: null }

  componentDidMount() {
    const { patientKey: key } = this.props.match.params

    const paths = {
      profile: `/patients/${key}`,
      antropometric_tests: `/tests/${key}/antropometry`,
      diseases: `/params/${key}/diseases`,
      insulin_resistance: `/params/${key}/insulin_resistance`,
      gynae: `/params/${key}/gynae`,
      andrology_questionnaire: `/writings/${key}/questionnaire`,
      andrology_mass_history: `/writings/${key}/andrology`,
      andrology_params: `/params/${key}/andrology`,
      ration_meals: `/ration/${key}`,
      ration_params: `/params/${key}/ration`,
      tooltests_uzi_thyroid: `/writings/${key}/uzi_thyroid`,
      tooltests_uzi_abdomen: `/writings/${key}/uzi_abdomen`,
      tooltests_uzi_pelvic: `/writings/${key}/uzi_pelvic`,
      blood_last: `/tests/${key}/blood/last`,
      parasitosis_last: `/tests/${key}/parasitosis/last`,
      osipov_last: `/tests/${key}/osipov/last`,
      cortisol_last: `/tests/${key}/cortisol/last`,
      saliva_last: `/tests/${key}/saliva/last`,
      amino_acids_last: `/tests/${key}/amino_acids/last`,
      organic_acids_last: `/tests/${key}/organic_acids/last`,
      methylated_arginine_last: `tests/${key}/xma/last`,
      blood_cli_last: `/tests/${key}/blood_cli/last`,
      feces_last: `/tests/${key}/feces/last`,
      diagnosis: `/params/${key}/diagnosis`,
      procedures: `/procedures/${key}`,
      consultations: `/consultations/${key}`,
    }

    fetchData(paths).then(data => {
      this.setState({ data })
    })
  }

  render() {
    const { data } = this.state
    const { patientKey } = this.props.match.params

    if (!data) {
      return <Spinner text="Загрузка данных..." />
    }

    return (
      <div className="screen screen-front">
        <nav>
          <Link to={`/card/${patientKey}`}>Редактировать</Link>
        </nav>

        {/* profile */}
        <h3>Основная информация</h3>
        <FrontProfile {...data.profile} />

        {/* antropometrix */}
        <h3>Антропометрические показатели</h3>
        <FrontAntropometry data={data.antropometric_tests} />

        {/* diseases */}
        <FrontParams
          set="diseases"
          data={data.diseases}
          title="Сопутствующие заболевания"
        />

        {/* insulin resistance */}
        <FrontParams
          set="insulin_resistance"
          data={data.insulin_resistance}
          title="Оценка ИР"
        />

        {/* gynae */}
        <FrontParams
          set="gynae"
          data={data.gynae}
          title="Гинекологический анамнез"
        />

        {/* andrology */}
        <FrontAndrology
          questionnaire={data.andrology_questionnaire}
          massHistory={data.andrology_mass_history}
          params={data.andrology_params}
        />

        {/* ration */}
        <FrontRation meals={data.ration_meals} params={data.ration_params} />

        {/* tooled tests */}
        <FrontToolTests
          texts={{
            uzi_thyroid: data.tooltests_uzi_thyroid,
            uzi_abdomen: data.tooltests_uzi_abdomen,
            uzi_pelvic: data.tooltests_uzi_pelvic,
          }}
        />

        <h3>Лабораторные показатели</h3>

        {/* blood tests */}
        <h4>Биохимический анализ крови</h4>
        <FrontLabTests
          values={data.blood_last || {}}
          testsSection="blood_tests"
          groupSection="blood_tests_groups"
        />

        <h4>Диагностика паразитозов</h4>
        <FrontLabTests
          values={data.parasitosis_last || {}}
          testsSection="parasitosis"
        />

        <h4>Дисбиоз по Осипову</h4>
        <FrontLabTests
          values={data.osipov_last || {}}
          testsSection="dysbiosis_osipov"
        />

        <h4>Кортизол и ДГЕА в слюне</h4>
        <FrontLabTests
          values={data.cortisol_last || {}}
          testsSection="cortisol"
        />

        <h4>Анализы слюны</h4>
        <FrontLabTests values={data.saliva_last || {}} testsSection="saliva" />

        <h4>Аминокислоты в моче</h4>
        <FrontLabTests
          values={data.amino_acids_last || {}}
          testsSection="amino_acids"
        />

        <h4>Органические кислоты</h4>
        <FrontLabTests
          values={data.organic_acids_last || {}}
          testsSection="organic_acids"
          groupSection="organic_acids_groups"
        />

        <h4>Метилированные производные аргинина</h4>
        <FrontLabTests
          values={data.methylated_arginine_last || {}}
          testsSection="xma"
        />

        <h4>Клинический анализ крови</h4>
        <FrontLabTests
          values={data.blood_cli_last || {}}
          testsSection="blood_clinic"
        />

        {/* feces tests */}
        <h4>Анализы кала</h4>
        <FrontLabTests
          values={data.feces_last || {}}
          testsSection="feces_tests"
        />

        {/* diagnosis */}
        <FrontDiagnosis
          set="diagnosis"
          data={data.diagnosis || {}}
          title="Диагноз"
        />

        <h3>Процедурный лист</h3>
        <FrontProcedures data={data.procedures || {}} />

        <h3>Консультации специалистов</h3>
        <FrontConsultations data={data.consultations || {}} />
      </div>
    )
  }
}

export default ScreenFront
