import React from 'react'
import moment from '../moment'

const TestsHistoryTable = ({ tests, groups }) => (
  <div className="tests-history tests-history-table">
    <table>

      <thead>
        <tr>
          <th className="date">Дата</th>
          <th className="value">Значение</th>
          <th>Комментарий</th>
        </tr>
      </thead>

      <tbody>
        {groups ? groups.map(group => (
          <React.Fragment key={group.key}>

            <tr className="group-header">
              <th colSpan={3}>{group.title}</th>
            </tr>

            <TestsHistoryTable.Rows tests={group.tests} />

          </React.Fragment>
        )) : (
          <TestsHistoryTable.Rows tests={tests} />
        )}
      </tbody>

    </table>
  </div>
)

TestsHistoryTable.Rows = ({ tests }) => (
  <React.Fragment>
    {tests.map(test => (
      <React.Fragment key={test.key}>
        <tr>
          <td colSpan={3}>{test.title}</td>
        </tr>
        {test.history.map(item => (
          <tr key={item.created}>
            <td className="date">{moment(item.created).format('L')}</td>
            <td className="value">{item.value}</td>
            <td>{item.comment}</td>
          </tr>
        ))}
      </React.Fragment>
    ))}
  </React.Fragment>
)

export default TestsHistoryTable
