import React from 'react'
import CoolLink from '../CoolLink'

const ConfigLinks = ({ baseUrl, sections }) => (
  <ul className="config-links">
    {Object.keys(sections).map(key => (
      <li key={key}>
        <CoolLink to={`${baseUrl}/${key}`}>{sections[key].title}</CoolLink>
      </li>
    ))}
  </ul>
)

export default ConfigLinks
