import moment from 'moment'
import React from 'react'

const ConsultationItem = ({ item, onDelete }) => {
  return (
    <div className="consultation-item">
      <div className="header">
        <div>
          <label>Дата:</label> {moment(item.date).format('L')}
        </div>
        <div />
        {onDelete && (
          <button type="button" onClick={() => onDelete(item)}>
            <i className="fa fa-remove"></i>
          </button>
        )}
      </div>

      {item.comments && (
        <>
          <label>Комментарий:</label>
          <div>{item.comments}</div>
        </>
      )}
    </div>
  )
}

export default ConsultationItem
