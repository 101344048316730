import React from 'react'

const ButtonAwesome = ({ type = "button", text, icon, ...props }) => (
  <button type={type} {...props}>
    {icon && <i className={`fa fa-${icon}`}></i>}
    {text && <span>{text}</span>}
  </button>
)

export default ButtonAwesome
