import React from 'react'

const BigTextView = ({ text }) => {

  if (!text || !text.length) {
    return <span className="no-data">(нет данных)</span>
  }

  return (
    <pre className="comment">
      {text}
    </pre>
  )
}


export default BigTextView
