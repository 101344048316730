import React, { useReducer } from 'react'

const ConsultationForm = ({ onCreate, onCancel }) => {
  const [values, update] = useReducer(
    (state, update) => ({ ...state, ...update }),
    {},
  )

  const handleSubmit = e => {
    e.preventDefault()
    onCreate(values)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="consultation-form">
        <div className="header">
          <div>
            <label>Дата:</label>
            <input
              type="date"
              required
              value={values.date}
              onChange={e => update({ date: e.target.value })}
            />
          </div>
        </div>

        <label>Комментарий:</label>
        <textarea
          required
          rows={5}
          value={values.comments}
          onChange={e => update({ comments: e.target.value })}
        />
      </div>

      <div className="buttons">
        <button type="submit">Создать</button>
        <button type="button" onClick={() => onCancel()}>
          Отмена
        </button>
      </div>
    </form>
  )
}

export default ConsultationForm
