import React from 'react'
import { withConfigSections } from '../hoc/config'

import MealsList from './MealsList'
import { ParamsList } from './ParamSet'

export const FrontRation = ({ meals, params }) => (
  <div className="fron-section front-section-ration">

    <h4>Примерный пищевой рацион за сутки</h4>

    <MealsList meals={meals} />

    <ParamsList data={params} />

  </div>
)

class FrontRationContainer extends React.Component {

  transformData() {

    const mealsHash = this.props.meals || {}
    const meals = Object.keys(mealsHash).map(time => {
      return { time, desc: mealsHash[time] }
    })

    const paramsHash = this.props.params || {}
    const params = this.props.paramsConfig
      .filter(param => (param.key in paramsHash))
      .map(param => {
        return { ...param, ...paramsHash[param.key] }
      })

    return { meals, params }
  }

  render() {
    const { meals, params } = this.transformData()
    return <FrontRation meals={meals} params={params} />
  }
}

export default withConfigSections({
  paramsConfig: {
    section: 'ration',
    transform: section => {
      return Object.keys(section).map(key => {
        const param = section[key]
        if (typeof param === 'string') {
          return { key, title: param, options: null }
        } else {
          return { ...param, key }
        }
      })
    }
  }
})(FrontRationContainer)