import React from 'react';

const NotFound = () =>  (
  <div className="inner">
    <h2>404 Not Found</h2>
    <p>Запрошенная страница не найдена!</p>
  </div>
)

export default NotFound
