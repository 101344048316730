import React from 'react'
import { withHandlers } from 'recompose'

export const OptionSelector = ({ name, options = [], handleChange, ...props }) => (
  <select name={name} onChange={handleChange} { ...props }>
    {options.map((option, index) => (
      <option key={index} value={option}>{option}</option>
    ))}
  </select>
)

export default withHandlers({
  handleChange: props => event => props.onChange && props.onChange(event.target.value)
})(OptionSelector)
