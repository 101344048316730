import { useMemo, useState } from 'react'

const getInitialProps = props => {
  return { open: false, ...props }
}

export function useDialog(props) {
  const [state, setState] = useState(() => getInitialProps(props))

  return useMemo(() => {
    const update = props => {
      setState({ ...state, ...props })
    }

    const open = (props = {}) => update({ ...props, open: true })
    const close = () => update({ open: false })

    return { state, update, open, close }
  }, [state])
}
