import React from 'react'
import { Route, Link } from 'react-router-dom'

const CoolLink = ({ to, children, wrapper = 'div', exact = true }) => (
  <Route path={to} exact={exact}>
    {({ match }) =>
      React.createElement(
        wrapper,
        { className: match ? 'active' : '' },
        <Link to={to}>{children}</Link>,
      )
    }
  </Route>
)

export default CoolLink
