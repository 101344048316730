import React from 'react'
import { withStateHandlers } from 'recompose'
import OptionSelector from './OptionSelector'

export const ParamsList = ({ data }) => (
  <div className="params-list">
    {data.length ? (
      <ul>
        {data.map(param => (
          <li key={param.key}>
            <div>
              {param.option ? (
                <span>
                  {param.title}:
                  <span className="param-option">{param.option}</span>
                </span>
              ) : (
                <span>{param.title}</span>
              )}
              {param.comment && (
                <pre className="param-comment">{param.comment}</pre>
              )}
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <p>(нет данных)</p>
    )}
  </div>
)

export const ParamsEditorList = ({ handleClickEdit, ...props }) => (
  <div>
    <ParamsList { ...props } />
    <button onClick={handleClickEdit}>Изменить</button>
  </div>
)

export const ParamsEditor = ({ params, data, handleSubmit }) => (
  <form className="params-editor" onSubmit={handleSubmit}>
    <ul>
      {params.map(param => (
        <ParamsEditor.Item
          key={param.key}
          param={param}
          data={data[param.key]}
        />
      ))}
    </ul>
    <button type="submit">Сохранить</button>
  </form>
)

export const ParamsEditorItem = ({ param, data, enabled, toggleEnabled, showComment, toggleComment }) => (
  <li>
    <div>

      <span>
        <input
          id={param.key}
          type="checkbox"
          name={`${param.key}[enabled]`}
          checked={enabled}
          onChange={toggleEnabled}
        />
        <label htmlFor={param.key}>{param.title}</label>

        {enabled && param.options && (
          <OptionSelector
            name={`${param.key}[option]`}
            options={param.options}
            value={data && data.option}
          />
        )}
      </span>

      {enabled && (
        <button onClick={toggleComment}>
          <i className={`fa ${showComment ? 'fa-remove' : 'fa-plus'}`}></i>
          {' '}
          комментарий
        </button>
      )}

    </div>

    {enabled && showComment && (
      <textarea
        name={`${param.key}[comment]`}
        className="param-comment"
        defaultValue={data && data.comment}
      />
    )}

  </li>
)

ParamsEditor.Item = withStateHandlers(
  props => ({
    enabled: !!props.data,
    showComment: !!(props.data && props.data.comment),
  }),
  {
    toggleEnabled: ({ enabled }) => () => ({ enabled: !enabled }),
    toggleComment: ({ showComment }) => (event) => {
      event.preventDefault()
      return { showComment: !showComment }
    }
  }
)(ParamsEditorItem)
